@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_variables.scss";

$main-image-padding: 27px;
$main-image-bg: #fff;
$main-image-margin: 0 0 10px 0;
$main-image-height: 495px;

/* laoder */

$loader-track-color: #d8d5dc;
$loader-body-color: #956688;
$loader-size: 60px;
$loader-weight: 10px;

/* arrows setting */

$arrow-color: #fff;
$arrow-bg: #406ff9;
$arrow-height: 20px;
$arrow-width: 20px;
$arrow-border-radius: 50%;
$arrow-font-size: 10px;
$arrow-prev-ico: "\e90b";
$arrow-next-ico: "\e90c";

/* thumb setting */

$thumb-height: 90px;
$thumb-padding-gutter: 8px;
$thumb-bg-color: #fff;
$thumb-padding-inner: 10px;
$thumb-border-width: 1px;

/* vertical differencies */

$carousel-right-space: 20px;

/* calcs */

$vertical-carousel-height: $main-image-height + $thumb-padding-gutter;
$vertical-thumb-height: ($vertical-carousel-height/4) - $thumb-padding-gutter;

/* spinner keyframe */

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* horizontal code */

.pux-gallery {
    &-main {
        background-color: $main-image-bg;
        padding: 0 $main-image-padding;
        margin: $main-image-margin;
        height: $main-image-height;
        display: table;
        width: 100%;

        &-image {
            margin-bottom: 0;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            height: $main-image-height - ($main-image-padding*2);
            z-index: 1;
            border: 1px solid $color-line-dark;

            img {
                max-height: $main-image-height - ($main-image-padding*2);
            }
        }

        &.loading {
            position: relative;

            &:before {
                content: '';
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: $main-image-bg;
                opacity: 0.8;
            }

            &:after {
                content: '';
                z-index: 3;
                border: $loader-weight solid $loader-track-color;
                /* Light grey */
                border-top: $loader-weight solid $loader-body-color;
                /* Blue */
                border-radius: 50%;
                width: $loader-size;
                height: $loader-size;
                animation: loader 2s linear infinite;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -$loader-size/2;
                margin-left: -$loader-size/2;
            }
        }
    }

    &-carousel {
        position: relative;
        height: $thumb-height;
        overflow: hidden;
        width: 100%;

        &-slick {
            margin: 0 $arrow-width + ($thumb-padding-gutter/2);
            position: initial;

            .slick-slide {
                padding-left: $thumb-padding-gutter/2;
                padding-right: $thumb-padding-gutter/2;
                display: table;
                margin: 0;
                border: 0;

                &-inner {
                    height: $thumb-height;
                    display: table-cell;
                    vertical-align: middle;
                    width: 100%;
                    text-align: center;
                    padding: $thumb-padding-inner;
                    background-color: $thumb-bg-color;
                    border: $thumb-border-width solid $color-line-dark;

                    img {
                        display: inline-block;
                        vertical-align: middle;
                        max-height: $thumb-height - ($thumb-padding-inner*2) - ($thumb-border-width*2);
                    }
                }
            }

            .slick-arrow {
                position: absolute;
                z-index: 1;
                top: 50%;
                @include transform(translate(0%, -50%));
                font-size: 0px;
                width: $arrow-width;
                height: $arrow-height;
                background-color: $arrow-bg;
                color: $arrow-color;
                border: 0;
                border-radius: $arrow-border-radius;
                cursor: pointer;
                text-align: center;
                @include transition(350ms);

                &:before {
                    font-family: "icomoon";
                    font-size: $arrow-font-size;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.slick-disabled {
                    border-color: #ddd;
                }

                &:hover {
                    background-color: darken($arrow-bg, 10);
                }
            }

            .slick-prev {
                left: 0;

                &:before {
                    content: $arrow-prev-ico;
                }

                &:hover {
                    &:before {}
                }

                &.slick-disabled {
                    &:before {}
                }
            }

            .slick-next {
                right: 0px;

                &:before {
                    content: $arrow-next-ico;
                }

                &:hover {
                    &:before {}
                }

                &.slick-disabled {
                    &:before {}
                }
            }
        }
    }
}

/* vertical code */

.pux-gallery {
    &-container {
        &.vertical {
            @include flex-block();
            @include flex-flow(row, wrap);

            .pux-gallery-main {
                @include media(xl) {
                    @include flex(80%);
                    @include order(2);
                    margin: ($arrow-width + ($thumb-padding-gutter/2)) 0;
                    height: $main-image-height - $thumb-padding-gutter;
                    max-width: 80%;
                }
            }

            .pux-gallery-carousel {
                @include media(xl) {
                    height: $vertical-carousel-height + ($arrow-width*2);
                    @include flex(20%);
                    @include order(1);
                    padding-right: $carousel-right-space;
                    max-width: 20%;

                    &-slick {
                        height: $vertical-carousel-height;
                        margin: $arrow-width 0;
                        position: relative;

                        .slick-slide {
                            padding-left: 0px;
                            padding-right: 0px;
                            padding-top: $thumb-padding-gutter/2;
                            padding-bottom: $thumb-padding-gutter/2;
                            height: $vertical-thumb-height;

                            &-inner {
                                height: $vertical-thumb-height;

                                img {
                                    max-height: $vertical-thumb-height - ($thumb-padding-inner*2) - ($thumb-border-width*2);
                                }
                            }
                        }

                        .slick-arrow {
                            top: auto;
                            left: 50%;
                            @include transform(translate(-50%,0) rotateZ(90deg));
                        }

                        .slick-prev {
                            top: -20px;
                        }

                        .slick-next {
                            bottom: -20px;
                        }
                    }
                }
            }
        }
    }
}
