
$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);

// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 15px;
$main-right-md: 15px;

// container
$container-max-width: 1200px;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 120px;
$fluid-container-padding-right-xl: 120px;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'Roboto', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 18px;
$min-font-size-perex: 16px;
$line-height-perex: 26px;

// small text
$font-size-small: 15px;
$min-font-size-small: 14px;
$line-height-small: 24px;

// main colors
$color-link: #005ba9;
$main-color: #005ba9;
$second-color: #002b50;
$color-line: #f6f6f6;
$color-line-dark: #dadada;
$base-black: #131313;
$base-white: #fff;
$main-color-light: #90defe;
$main-color-soft: #84cbe9;
$main-color-mute: #7496c5;
$main-color-hover: $main-color - #222;

// button primary
$btn-primary-background: $main-color;
$btn-primary-color: $base-white;

// button default
$btn-default-background: $second-color;
$btn-default-color: $base-white;

// ul, li
$ul-bullet: $base-black;
$ol-number: $base-black;

// table
$table-even-background: #fff;
$table-tr-border: #f1f1f1;

// form
$color-placeholder: #a7a7a7;

// inputs
$border-color: #e6e6e6;
$input-background: $base-white;
$focus-border-color: $main-color;
$input-height: 45px;
$textarea-height: $input-height * 3;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

// scale

$scale-xxl: 1;
$scale-xl: 0.8;
$scale-lg: 0.7;
$scale-md: 0.6;
$scale-sm: 0.5;
$scale-xs: 0.4;
$scale-xxs: 0.4;

$min-font-size: 15px;
$min-padding: 10px;
$min-margin: 10px;
