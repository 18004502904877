@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700,700i&subset=latin-ext");
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?nkirei");
  src: url("fonts/icomoon.eot?nkirei#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?nkirei") format("woff2"), url("fonts/icomoon.ttf?nkirei") format("truetype"), url("fonts/icomoon.woff?nkirei") format("woff"), url("fonts/icomoon.svg?nkirei#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-MAP-PIN_all-in-one .path1:before {
  content: "\e900";
  color: #005ba9; }

.icon-MAP-PIN_all-in-one .path2:before {
  content: "\e901";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path3:before {
  content: "\e902";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path4:before {
  content: "\e903";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path5:before {
  content: "\e904";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path6:before {
  content: "\e905";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path7:before {
  content: "\e906";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path8:before {
  content: "\e907";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path9:before {
  content: "\e908";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_all-in-one .path10:before {
  content: "\e909";
  margin-left: -0.771484375em;
  color: white; }

.icon-MAP-PIN_multiple:before {
  content: "\e90a";
  color: #005ba9; }

.icon-SLIDER_left-arrow:before {
  content: "\e90b";
  color: #fff; }

.icon-SLIDER_right-arrow:before {
  content: "\e90c";
  color: #fff; }

.icon-motiv .path1:before {
  content: "\e90d";
  color: #005ba9; }

.icon-motiv .path2:before {
  content: "\e90e";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path3:before {
  content: "\e90f";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path4:before {
  content: "\e910";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path5:before {
  content: "\e911";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path6:before {
  content: "\e912";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path7:before {
  content: "\e913";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path8:before {
  content: "\e914";
  margin-left: -0.955078125em;
  color: #005ba9; }

.icon-motiv .path9:before {
  content: "\e915";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path10:before {
  content: "\e916";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path11:before {
  content: "\e917";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path12:before {
  content: "\e918";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path13:before {
  content: "\e919";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path14:before {
  content: "\e91a";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path15:before {
  content: "\e91b";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path16:before {
  content: "\e91c";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path17:before {
  content: "\e91d";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path18:before {
  content: "\e91e";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path19:before {
  content: "\e91f";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path20:before {
  content: "\e920";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path21:before {
  content: "\e921";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path22:before {
  content: "\e922";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path23:before {
  content: "\e923";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path24:before {
  content: "\e924";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path25:before {
  content: "\e925";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path26:before {
  content: "\e926";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path27:before {
  content: "\e927";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path28:before {
  content: "\e928";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path29:before {
  content: "\e929";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path30:before {
  content: "\e92a";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path31:before {
  content: "\e92b";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path32:before {
  content: "\e92c";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path33:before {
  content: "\e92d";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path34:before {
  content: "\e92e";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path35:before {
  content: "\e92f";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path36:before {
  content: "\e930";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path37:before {
  content: "\e931";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path38:before {
  content: "\e932";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path39:before {
  content: "\e933";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path40:before {
  content: "\e934";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path41:before {
  content: "\e935";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path42:before {
  content: "\e936";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path43:before {
  content: "\e937";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-motiv .path44:before {
  content: "\e938";
  margin-left: -0.955078125em;
  color: #c1c1c1; }

.icon-BULLET_normal-text-bullet:before {
  content: "\e939";
  color: #4b4b4b; }

.icon-DOWNLOAD_file-download-ico:before {
  content: "\e93a";
  color: #005ba9; }

.icon-FOOTER_fac-ico:before {
  content: "\e93b";
  color: #84cbe9; }

.icon-FOOTER_mail-ico:before {
  content: "\e93c";
  color: #84cbe9; }

.icon-FOOTER_phone-ico:before {
  content: "\e93d";
  color: #84cbe9; }

.icon-HEADER_cz-language .path1:before {
  content: "\e93e";
  color: #d7141a; }

.icon-HEADER_cz-language .path2:before {
  content: "\e93f";
  margin-left: -1.5em;
  color: #f0f0f0; }

.icon-HEADER_cz-language .path3:before {
  content: "\e940";
  margin-left: -1.5em;
  color: #11457e; }

.icon-HEADER_logo-casio:before {
  content: "\e941";
  color: #005ba9; }

.icon-HEADER_search:before {
  content: "\e942";
  color: #005ba9; }

.icon-HEADER-MENU_submenu-symbol:before {
  content: "\e943";
  color: #005ba9; }

.icon-HYPERLINK_bigger-text-arrow_blue:before {
  content: "\e944";
  color: #005ba9; }

.icon-HYPERLINK_normal-text-arrow_blue:before {
  content: "\e945";
  color: #005ba9; }

.icon-HYPERLINK_normal-text-arrow_white:before {
  content: "\e946";
  color: #fff; }

.icon-CHECKBOX_empty:before {
  content: "\e947";
  color: #005ba9; }

.icon-CHECKBOX_checked:before {
  content: "\e948";
  color: #005ba9; }

.icon-MAP-PIN_1of2_pin-bg:before {
  content: "\e949";
  color: #005ba9; }

.icon-MAP-PIN_2of2_ico-in-pin:before {
  content: "\e94a";
  color: #fff; }

.grid-small {
  margin: 0 -5px; }
  .grid-small > div {
    padding: 0 5px; }

@media (min-width: 768px) {
  .grid {
    display: flex; }
    .grid-5 > div {
      float: left;
      width: 20%; } }

.block {
  padding: 30px 0; }
  .block-soft {
    background: #f6f6f6; }
  .block-title {
    display: block;
    font-weight: 700;
    text-align: center;
    color: #002b50;
    margin-bottom: 70px;
    font-size: 28px;
    font-size: 1.75rem; }
  @media (min-width: 768px) {
    .block {
      padding: 60px 0; } }

.ico-tooltip {
  position: relative;
  top: -2px;
  margin: 0 0 0 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  background: #005ba9;
  text-align: center;
  color: #fff;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
  .ico-tooltip:before {
    content: 'i';
    font-family: inherit !important;
    font-size: 12px;
    font-size: 0.75rem; }

.tooltip.show {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #fff; }

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #fff; }

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff; }

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff; }

.tooltip .arrow:before {
  z-index: 2; }

.tooltip .arrow:after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 1;
  border-color: transparent;
  border-style: solid;
  margin-top: -3px;
  border-width: 6px 6px 6px 0;
  border-right-color: #dadada; }

.tooltip-inner {
  background: #fff;
  color: #131313;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 1px #131313;
  -moz-box-shadow: 0px 0px 1px #131313;
  box-shadow: 0px 0px 1px #131313; }

.tooltiptext {
  display: none; }

.tooltipbox {
  background: #fff;
  padding: 15px;
  border: 1px solid #dadada;
  color: #002b50;
  min-width: 250px;
  z-index: 80 !important;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 21px;
  line-height: 1.3125rem; }
  .tooltipbox.qtip-pos-rc, .tooltipbox.qtip-pos-lc {
    margin: 0 0 0 20px; }
    .tooltipbox.qtip-pos-rc:before, .tooltipbox.qtip-pos-rc:after, .tooltipbox.qtip-pos-lc:before, .tooltipbox.qtip-pos-lc:after {
      content: "";
      position: absolute;
      left: -15px;
      top: 50%;
      z-index: 2;
      border-color: transparent;
      border-style: solid;
      margin-top: -10px;
      border-width: 10px 15px 10px 0;
      border-right-color: #fff; }
    .tooltipbox.qtip-pos-rc:after, .tooltipbox.qtip-pos-lc:after {
      z-index: 1;
      margin-top: -11px;
      left: -16px;
      border-width: 11px 16px 11px 0;
      border-right-color: #dadada; }
  .tooltipbox.qtip-pos-rc {
    margin: 0 0 0 -20px; }
    .tooltipbox.qtip-pos-rc:after, .tooltipbox.qtip-pos-rc:before {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      left: 100%; }
  .tooltipbox.qtip-pos-lb, .tooltipbox.qtip-pos-rb {
    margin-top: -10px; }
    .tooltipbox.qtip-pos-lb:after, .tooltipbox.qtip-pos-lb:before, .tooltipbox.qtip-pos-rb:after, .tooltipbox.qtip-pos-rb:before {
      display: none; }

.flex-grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-direction: wrap;
  -webkit-flex-direction: row;
  flex-wrap: wrap; }

.flex-item {
  position: relative; }

.box {
  height: 100%;
  padding: 0 0 30px; }

#map-canvas {
  height: 300px; }
  @media (min-width: 480px) {
    #map-canvas {
      height: 300px; } }
  @media (min-width: 545px) {
    #map-canvas {
      height: 300px; } }
  @media (min-width: 768px) {
    #map-canvas {
      height: 300px; } }
  @media (min-width: 992px) {
    #map-canvas {
      height: 350px; } }
  @media (min-width: 1200px) {
    #map-canvas {
      height: 400px; } }
  @media (min-width: 1400px) {
    #map-canvas {
      height: 500px; } }

.marker-detail,
.map-points {
  display: none; }

.cookie-law-approval-wrapper {
  width: 100%;
  text-align: center;
  background: #fff;
  border-top: #dadada 1px solid;
  color: #005ba9;
  padding: 8px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999; }

.cookie-law-approval-content a {
  color: inherit; }
  .cookie-law-approval-content a:hover, .cookie-law-approval-content a:focus {
    color: inherit;
    text-decoration: underline; }

.cookie-law-approval-close {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  color: #fff;
  background: #005ba9;
  padding: 0 20px !important;
  position: relative;
  margin: 0 0 0 30px;
  text-transform: uppercase;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px; }
  .cookie-law-approval-close:hover {
    text-decoration: none;
    color: #fff;
    background: #003987; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 15px;
      padding-right: 15px; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -15px;
      margin-right: -15px; } }

.pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
.pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
.pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
.pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
.pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
.pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
    .pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
    .pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
    .pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
    .pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
    .pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 15px;
      padding-right: 15px; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-righ: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 15px;
      padding-righ: 15px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 120px;
      padding-righ: 120px; } }

.header {
  position: fixed;
  z-index: 100;
  top: -13px;
  left: 0;
  width: 100%;
  background: #fff;
  white-space: nowrap;
  text-align: right;
  height: 60px;
  border-bottom: 1px solid #dadada;
  font-size: 0;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s; }
  .header-logo {
    max-width: 40%;
    float: left;
    padding: 22px 0 0;
    line-height: 26px;
    min-height: 26px; }
  .header-menu {
    display: inline-block;
    vertical-align: top;
    text-align: left; }
    .header-menu ul {
      padding: 0;
      margin: 0;
      color: #005ba9; }
      .header-menu ul li {
        display: inline-block;
        position: relative; }
        .header-menu ul li a {
          display: block;
          color: inherit;
          padding: 24px 20px;
          text-transform: uppercase;
          font-size: 16px;
          font-size: 1rem;
          -moz-transition: padding ease-in-out 0.3s;
          -o-transition: padding ease-in-out 0.3s;
          -webkit-transition: padding ease-in-out 0.3s;
          transition: padding ease-in-out 0.3s; }
        .header-menu ul li ul {
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 100;
          background: #005ba9;
          color: #fff;
          padding: 15px 0; }
          .header-menu ul li ul li {
            display: block; }
            .header-menu ul li ul li a {
              padding: 10px 30px;
              text-transform: none;
              font-weight: 400;
              font-size: 15px;
              font-size: 0.9375rem; }
              .header-menu ul li ul li a:hover {
                color: #84cbe9; }
        .header-menu ul li.Highlighted a, .header-menu ul li:hover a {
          background: #005ba9;
          color: #fff;
          text-decoration: none; }
        .header-menu ul li:hover ul {
          display: block; }
        .header-menu ul li.arrow > a:after {
          display: inline-block;
          vertical-align: top;
          content: '\e943';
          font-family: 'icommon';
          margin: 8px 0 0 10px;
          font-size: 6px;
          font-size: 0.375rem; }
  .header-search {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    font-size: 0.9375rem; }
    .header-search-toggle {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      padding: 24px 0 13px;
      width: 50px;
      font-size: 16px;
      font-size: 1rem;
      -moz-transition: all ease-in-out 0.3s;
      -o-transition: all ease-in-out 0.3s;
      -webkit-transition: all ease-in-out 0.3s;
      transition: all ease-in-out 0.3s; }
      .header-search-toggle:before {
        content: '\e942';
        font-family: 'icommon';
        display: inline-block;
        width: 100%;
        text-align: center; }
      .header-search-toggle.active {
        background: #005ba9;
        color: #fff; }
    .header-search-form {
      white-space: normal;
      width: 100%;
      display: none;
      position: absolute;
      z-index: 50;
      right: 0;
      top: 100%;
      background: #005ba9;
      color: #fff;
      padding: 20px;
      text-align: center; }
      .header-search-form input[type="text"] {
        display: block;
        border-color: #84cbe9;
        color: #fff;
        background: none; }
        .header-search-form input[type="text"]::-webkit-input-placeholder {
          color: #7496c5; }
        .header-search-form input[type="text"]:-moz-placeholder {
          color: #7496c5; }
        .header-search-form input[type="text"]::-moz-placeholder {
          color: #7496c5; }
        .header-search-form input[type="text"]:-ms-input-placeholder {
          color: #7496c5; }
        .header-search-form input[type="text"]:focus {
          border-color: #fff; }
      .header-search-form input[type="submit"] {
        float: none;
        background: #fff;
        color: #005ba9;
        margin: 15px 0 0; }
        .header-search-form input[type="submit"]:hover {
          color: #003987;
          background: #fff; }
  .header-lang {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 900;
    padding: 24px 15px;
    font-size: 16px;
    font-size: 1rem;
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s; }
    .header-lang .header-language-inner {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 900;
      border: transparent 0px solid;
      padding: 24px 15px 5px;
      background: #fff;
      border-radius: 0 0 3px 3px;
      -moz-border-radius: 0 0 3px 3px;
      -webkit-border-radius: 0 0 3px 3px;
      -webkit-box-shadow: 0 0 1px #dadada;
      -moz-box-shadow: 0 0 1px #dadada;
      box-shadow: 0 0 1px #dadada; }
      .header-lang .header-language-inner ul {
        margin: 0;
        padding: 0;
        list-style-type: none; }
        .header-lang .header-language-inner ul li a {
          margin: 0 0 10px 0; }
          .header-lang .header-language-inner ul li a:hover {
            -webkit-box-shadow: 0 0 1px #005ba9;
            -moz-box-shadow: 0 0 1px #005ba9;
            box-shadow: 0 0 1px #005ba9; }
          .header-lang .header-language-inner ul li a.active {
            display: none; }
        .header-lang .header-language-inner ul li:before {
          display: none; }
    .header-lang .cs-cz,
    .header-lang .sk-sk,
    .header-lang .hu-hu {
      display: inline-block;
      width: 18px;
      height: 12px;
      background: url("../svg/HEADER_cz-language.svg") no-repeat center center;
      overflow: hidden;
      padding: 0 0 0 100%; }
    .header-lang .sk-sk {
      background-image: url("../svg/HEADER_sk-language.svg"); }
    .header-lang .hu-hu {
      background: url("../svg/HEADER_hu-language.svg"); }
    .header-lang:hover .header-language-inner {
      display: block; }
  .header .pux-meanmenu-reveal {
    display: block;
    right: 100px;
    top: 13px;
    border: none; }
  .header .mobile-menu {
    top: 60px;
    font-size: 15px;
    font-size: 0.9375rem; }
    .header .mobile-menu .mean-nav ul li a.mean-expand {
      padding: 16px 16px 17px !important; }
  @media (min-width: 545px) {
    .header-search-form {
      padding-right: 200px; }
      .header-search-form input[type="submit"] {
        position: absolute;
        right: 20px;
        top: 20px;
        margin: 0; } }
  @media (min-width: 768px) {
    .header-search {
      position: relative; }
      .header-search-form {
        width: 560px; } }
  @media (min-width: 992px) {
    .header {
      top: 0;
      height: auto; }
      .header-search-toggle {
        padding-bottom: 24px; }
      .header .pux-meanmenu-reveal {
        display: none; } }
  @media (min-width: 1200px) {
    .header-menu ul li a {
      padding-left: 28px;
      padding-right: 28px; }
    .header-lang, .header .header-language-inner {
      padding-left: 28px;
      padding-right: 28px; } }

.header-placholder {
  height: 45px;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s; }
  @media (min-width: 992px) {
    .header-placholder {
      height: 70px; } }

.scrolling .header {
  top: -13px; }
  .scrolling .header-menu > ul > li > a, .scrolling .header-search-toggle, .scrolling .header-lang {
    padding-bottom: 13px; }

@media (min-width: 992px) {
  .scrolling .header-placholder {
    height: 30px; } }

.promo {
  position: relative; }
  .promo-item {
    background-repeat: no-repeat;
    background-position: 15% 50%;
    -webkit-background-size: cover !important;
    background-size: cover !important; }
    .promo-item-content {
      margin: 70px 0 0;
      min-height: 350px;
      padding: 35px 45px;
      color: #fff;
      font-weight: 400;
      font-size: 18px;
      font-size: 1.125rem; }
      .promo-item-content h1, .promo-item-content h2, .promo-item-content h3, .promo-item-content h4 {
        color: inherit; }
      .promo-item-content-title {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 36px;
        line-height: 2.25rem; }
        @media (min-width: 480px) {
          .promo-item-content-title {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 36px;
            line-height: 2.25rem; } }
        @media (min-width: 545px) {
          .promo-item-content-title {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 36px;
            line-height: 2.25rem; } }
        @media (min-width: 768px) {
          .promo-item-content-title {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 36px;
            line-height: 2.25rem; } }
        @media (min-width: 992px) {
          .promo-item-content-title {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 36px;
            line-height: 2.25rem; } }
        @media (min-width: 1200px) {
          .promo-item-content-title {
            font-size: 29px;
            font-size: 1.8125rem;
            line-height: 39px;
            line-height: 2.4375rem; } }
        @media (min-width: 1400px) {
          .promo-item-content-title {
            font-size: 36px;
            font-size: 2.25rem;
            line-height: 48px;
            line-height: 3rem; } }
      .promo-item-content-btn {
        display: table;
        background: #005ba9;
        color: #fff !important;
        padding: 10px 40px;
        margin: 30px 0 0; }
        .promo-item-content-btn:hover, .promo-item-content-btn:focus {
          text-decoration: none; }
      .promo-item-content-light {
        color: #131313; }
      .promo-item-content-dark {
        background: rgba(0, 43, 80, 0.8); }
        .promo-item-content-dark a {
          color: #90defe; }
        .promo-item-content-dark .promo-item-content-btn {
          background: #fff;
          color: #005ba9 !important; }
  .promo-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0; }
    .promo-nav-container {
      position: relative; }
    .promo-nav-btn {
      position: absolute;
      top: -20px;
      display: block;
      width: 40px;
      height: 40px;
      background: #005ba9;
      color: #fff;
      overflow: hidden; }
      .promo-nav-btn:before {
        content: '\e90b';
        font-family: 'icommon';
        display: block;
        width: 100%;
        line-height: 40px;
        text-align: center; }
      .promo-nav-btn:hover, .promo-nav-btn:focus {
        background: #84cbe9;
        color: #005ba9;
        text-decoration: none; }
      .promo-nav-btn-prev {
        left: 0px; }
      .promo-nav-btn-next {
        right: 0px; }
        .promo-nav-btn-next:before {
          content: '\e90c'; }
  @media (min-width: 1400px) {
    .promo-nav-btn-prev {
      left: -85px; }
    .promo-nav-btn-next {
      right: -85px; } }

.product-box {
  display: block;
  margin: 0 0 30px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  padding: 15px 15px 180px;
  overflow: hidden;
  position: relative;
  color: #131313;
  background-image: -webkit-linear-gradient(top, #555 0%, #333 100%);
  background-image: -o-linear-gradient(top, #555 0%, #333 100%);
  background-image: linear-gradient(to bottom, #555 0%, #333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF555555', endColorstr='#FF333333', GradientType=0); }
  .product-box span {
    display: table;
    padding: 20px 0 0;
    color: #005ba9; }
  .product-box-title {
    font-weight: 400;
    line-height: 1.3em;
    margin: 0; }
  .product-box img {
    position: absolute;
    max-width: 320px;
    top: 50%;
    right: 20px;
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s; }
  .product-box:hover {
    text-decoration: none;
    color: #131313; }
    .product-box:hover span {
      color: #003987; }
    .product-box:hover img {
      -ms-transform: scale(1.1, 1.1);
      -webkit-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1); }
  .product-box-scient {
    background-image: -webkit-linear-gradient(top, #bce1de 0%, #addbd7 100%);
    background-image: -o-linear-gradient(top, #bce1de 0%, #addbd7 100%);
    background-image: linear-gradient(to bottom, #bce1de 0%, #addbd7 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFBCE1DE', endColorstr='#FFADDBD7', GradientType=0); }
  .product-box-office {
    background-image: -webkit-linear-gradient(top, #e6ed96 0%, #e1e97f 100%);
    background-image: -o-linear-gradient(top, #e6ed96 0%, #e1e97f 100%);
    background-image: linear-gradient(to bottom, #e6ed96 0%, #e1e97f 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFE6ED96', endColorstr='#FFE1E97F', GradientType=0); }
  .product-box-print {
    background-image: -webkit-linear-gradient(top, #fec66c 0%, #feba4c 100%);
    background-image: -o-linear-gradient(top, #fec66c 0%, #feba4c 100%);
    background-image: linear-gradient(to bottom, #fec66c 0%, #feba4c 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFEC66C', endColorstr='#FFFEBA4C', GradientType=0); }
    .product-box-print img {
      right: -50px; }
  @media (min-width: 480px) {
    .product-box img {
      top: 30%; } }
  @media (min-width: 545px) {
    .product-box {
      padding-bottom: 100px;
      padding-right: 250px; }
      .product-box img {
        top: 10%; } }
  @media (min-width: 768px) {
    .product-box {
      height: 200px; } }
  @media (min-width: 992px) {
    .product-box {
      padding: 35px 300px 35px 45px; }
      .product-box span {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: inherit; }
      .product-box-scient {
        background-image: -webkit-linear-gradient(top, #bce1de 0%, #addbd7 100%);
        background-image: -o-linear-gradient(top, #bce1de 0%, #addbd7 100%);
        background-image: linear-gradient(to bottom, #bce1de 0%, #addbd7 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFBCE1DE', endColorstr='#FFADDBD7', GradientType=0); }
        .product-box-scient img {
          top: -50px; }
      .product-box-office {
        background-image: -webkit-linear-gradient(top, #e6ed96 0%, #e1e97f 100%);
        background-image: -o-linear-gradient(top, #e6ed96 0%, #e1e97f 100%);
        background-image: linear-gradient(to bottom, #e6ed96 0%, #e1e97f 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFE6ED96', endColorstr='#FFE1E97F', GradientType=0); }
        .product-box-office img {
          top: 10px; }
      .product-box-print {
        background-image: -webkit-linear-gradient(top, #fec66c 0%, #feba4c 100%);
        background-image: -o-linear-gradient(top, #fec66c 0%, #feba4c 100%);
        background-image: linear-gradient(to bottom, #fec66c 0%, #feba4c 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFEC66C', endColorstr='#FFFEBA4C', GradientType=0); }
        .product-box-print img {
          right: -50px; } }

@media (min-width: 992px) {
  .product-box-alt.product-box {
    padding: 20px 30px;
    height: 240px; }
    .product-box-alt.product-box span {
      position: relative;
      padding: 20px 0 0; }
    .product-box-alt.product-box img {
      width: 50%; }
    .product-box-alt.product-box-scient img {
      top: 110px;
      right: 20px; }
    .product-box-alt.product-box-office img {
      top: 110px;
      right: 20px; }
    .product-box-alt.product-box-print img {
      width: 70%;
      top: 110px;
      right: -40px; } }

.news-banner {
  display: block;
  height: 400px;
  position: relative;
  background-position: center center;
  -webkit-background-size: cover !important;
  background-size: cover !important; }
  .news-banner-content {
    display: block;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 30px;
    font-size: 28px;
    font-size: 1.75rem;
    background-image: -webkit-linear-gradient(top, rgba(0, 43, 80, 0.8) 0%, #000 100%);
    background-image: -o-linear-gradient(top, rgba(0, 43, 80, 0.8) 0%, #000 100%);
    background-image: linear-gradient(to bottom, rgba(0, 43, 80, 0.8) 0%, #000 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC002B50', endColorstr='#FF000000', GradientType=0); }
    .news-banner-content span {
      display: table;
      margin: 20px 0 0;
      color: inherit;
      font-size: 15px;
      font-size: 0.9375rem; }
  .news-banner:hover .news-banner-content span:after {
    margin-left: 13px; }
  @media (min-width: 768px) {
    .news-banner {
      height: 660px; } }
  @media (min-width: 992px) {
    .news-banner-content {
      padding: 40px 100px 0 40px; } }

.EditMode header,
.DesignMode header {
  position: static; }

.article-list {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .article-list {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .article-list {
      padding-top: 25px; } }
  @media (min-width: 768px) {
    .article-list {
      padding-top: 30px; } }
  @media (min-width: 992px) {
    .article-list {
      padding-top: 35px; } }
  @media (min-width: 1200px) {
    .article-list {
      padding-top: 40px; } }
  @media (min-width: 1400px) {
    .article-list {
      padding-top: 50px; } }

.article-item {
  display: block;
  margin: 0 0 30px;
  color: #002b50; }
  .article-item:hover {
    color: #005ba9; }
    .article-item:hover span {
      color: #003987; }
  .article-item-image {
    display: block;
    width: 100%;
    height: 260px;
    -webkit-background-size: cover !important;
    background-size: cover !important; }
  .article-item-content {
    width: 100%;
    background: #f6f6f6;
    padding: 20px 25px; }
    .article-item-content-title {
      display: block;
      font-weight: 400;
      margin: 0 0 15px;
      color: #131313;
      font-size: 22px;
      font-size: 1.375rem; }
      @media (min-width: 480px) {
        .article-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 545px) {
        .article-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 768px) {
        .article-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 992px) {
        .article-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 1200px) {
        .article-item-content-title {
          font-size: 23px;
          font-size: 1.4375rem; } }
      @media (min-width: 1400px) {
        .article-item-content-title {
          font-size: 28px;
          font-size: 1.75rem; } }
    .article-item-content span {
      display: table;
      margin: 25px 0 0;
      color: #005ba9;
      font-weight: 400;
      font-size: 16px;
      font-size: 1rem; }
      .article-item-content span:before {
        display: inline-block;
        vertical-align: baseline;
        position: relative;
        top: -1px;
        content: '\e90c';
        font-family: 'icommon';
        margin: 0 10px 0 0;
        padding: 0 0 2px;
        font-size: 12px;
        font-size: 0.75rem;
        -moz-transition: all ease-in-out 0.1s;
        -o-transition: all ease-in-out 0.1s;
        -webkit-transition: all ease-in-out 0.1s;
        transition: all ease-in-out 0.1s; }

@media (min-width: 768px) {
  .article-item {
    display: flex; }
    .article-item-image {
      width: 400px;
      height: 100%; }
    .article-item-content {
      padding: 20px 30px; } }

.map-search {
  padding: 0 0 30px;
  text-align: right; }
  .map-search input[type="text"] {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    max-width: 60%;
    margin: 0 7px 0 0; }
  .map-search input[type="submit"],
  .map-search a {
    width: 160px;
    max-width: 35%;
    display: inline-block;
    height: 45px;
    line-height: 45px;
    background: #005ba9;
    color: #fff;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px; }
    .map-search input[type="submit"]:focus, .map-search input[type="submit"]:hover,
    .map-search a:focus,
    .map-search a:hover {
      background: #003987;
      color: #fff; }

@media (min-width: 768px) {
  .map-search {
    margin-top: -70px; } }

@media (min-width: 1400px) {
  .map-search {
    margin-top: -85px; } }

.map .marker-detail-position {
  min-width: 300px;
  margin-left: -40px; }

.map .marker-detail-close {
  opacity: 1 !important; }
  .map .marker-detail-close img {
    display: none !important; }
  .map .marker-detail-close:before, .map .marker-detail-close:after {
    content: '';
    display: block;
    width: 2px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .map .marker-detail-close:after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.map .marker-detail-flyout {
  width: 100% !important; }
  .map .marker-detail-flyout > div:nth-child(1) {
    display: none; }
  .map .marker-detail-flyout > div:nth-child(2) {
    background: #005ba9 !important;
    border: none !important;
    width: 100% !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important; }
    .map .marker-detail-flyout > div:nth-child(2):before {
      content: '';
      display: block;
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin: 0 0 0 -5px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid #005ba9; }
  .map .marker-detail-flyout > div:nth-child(3) {
    display: none; }
  .map .marker-detail-flyout > div:nth-child(4) {
    background: none !important; }

.map .marker-detail-content {
  color: #fff;
  padding: 20px 20px 0;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  font-size: 15px;
  font-size: 0.9375rem; }
  .map .marker-detail-content * {
    color: inherit; }
  .map .marker-detail-content h2 {
    font-size: 18px;
    font-size: 1.125rem;
    margin: 0 0 20px; }
  .map .marker-detail-content a {
    color: #84cbe9; }

.store-list {
  margin: 50px 0;
  color: #002b50; }

.store-item {
  padding: 20px 25px;
  height: 100%;
  background: #f6f6f6;
  font-weight: 300; }
  .store-item h2 {
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 18px;
    font-size: 1.125rem; }
  .store-item strong {
    font-weight: 400; }

@media (min-width: 768px) {
  .store-item {
    padding: 25px 30px; } }

.office {
  color: #131313;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem; }
  .office-contact {
    line-height: 2em; }
    .office-contact em {
      font-style: normal;
      display: inline-block;
      width: 70px; }

.not-found img {
  display: block;
  margin: 50px auto; }

.search-form {
  text-align: center;
  margin: 0 0 30px; }
  .search-form input[type="text"] {
    display: inline-block;
    width: 100%;
    border-color: #dadada;
    color: #131313;
    background: none;
    margin: 0 15px 15px 0;
    vertical-align: top; }
    .search-form input[type="text"]::-webkit-input-placeholder {
      color: #7496c5; }
    .search-form input[type="text"]:-moz-placeholder {
      color: #7496c5; }
    .search-form input[type="text"]::-moz-placeholder {
      color: #7496c5; }
    .search-form input[type="text"]:-ms-input-placeholder {
      color: #7496c5; }
    .search-form input[type="text"]:focus {
      border-color: #005ba9; }
  .search-form input[type="submit"] {
    margin: 0;
    background: #005ba9;
    color: #fff; }
    .search-form input[type="submit"]:hover {
      color: #fff;
      background: #003987; }

.search-item-box {
  border: 1px solid #f6f6f6;
  padding: 20px;
  margin: 0 0 20px; }

.search-item-name {
  display: block;
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem; }
  @media (min-width: 480px) {
    .search-item-name {
      font-size: 20px;
      font-size: 1.25rem; } }
  @media (min-width: 545px) {
    .search-item-name {
      font-size: 20px;
      font-size: 1.25rem; } }
  @media (min-width: 768px) {
    .search-item-name {
      font-size: 20px;
      font-size: 1.25rem; } }
  @media (min-width: 992px) {
    .search-item-name {
      font-size: 20px;
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .search-item-name {
      font-size: 23px;
      font-size: 1.4375rem; } }
  @media (min-width: 1400px) {
    .search-item-name {
      font-size: 28px;
      font-size: 1.75rem; } }

.search-item-relevation {
  margin: 0 -20px;
  position: relative;
  top: 20px; }
  .search-item-relevation-bar {
    text-align: center;
    height: 20px;
    line-height: 20px;
    background: #005ba9;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem; }

@media (min-width: 545px) {
  .search-form {
    text-align: left; }
    .search-form input[type="text"] {
      display: inline-block;
      width: 300px;
      max-width: 50%; } }

@media (min-width: 768px) {
  .search-item-box {
    padding: 30px;
    margin: 0 0 30px; }
  .search-item-relevation {
    margin: 0 -30px;
    position: relative;
    top: 30px; } }

.show-more-container {
  text-align: center;
  padding: 40px 0 0; }
  .show-more-container a.arrow-down-link {
    display: inline-block;
    padding: 15px 40px;
    background: #005ba9;
    color: #fff; }
    .show-more-container a.arrow-down-link:hover {
      background: #003987;
      color: #fff; }

.footer {
  background: #005ba9;
  color: #fff;
  padding: 45px 0 0;
  text-align: center; }
  .footer a {
    color: inherit; }
  .footer ul {
    list-style-type: none;
    padding: 0; }
    .footer ul li {
      padding: 0 0 20px; }
  .footer-contact {
    font-weight: 700;
    font-size: 18px;
    font-size: 1.125rem; }
    .footer-contact span {
      display: inline-block;
      vertical-align: middle;
      border: 2px solid #90defe;
      color: #90defe;
      width: 32px;
      text-transform: uppercase;
      text-align: center;
      margin: 0 15px 0 0;
      padding: 2px 0 0;
      font-size: 8px;
      font-size: 0.5rem;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px; }
    .footer-contact ul li:before {
      display: inline-block;
      vertical-align: baseline;
      position: relative;
      font-family: 'icommon';
      margin: 0 10px 0 0;
      color: #90defe;
      font-weight: 300;
      font-size: 17px;
      font-size: 1.0625rem; }
    .footer-contact ul li.footer-contact-phone:before {
      content: '\e93d'; }
    .footer-contact ul li.footer-contact-fax:before {
      content: '\e93b'; }
    .footer-contact ul li.footer-contact-email:before {
      content: '\e93c'; }
  .footer-copyright {
    background: #131313;
    padding: 20px 0;
    margin: 20px 0 0; }
    .footer-copyright a {
      color: #90defe; }
    .footer-copyright-right {
      padding: 15px 0 0;
      display: block; }
  @media (min-width: 768px) {
    .footer {
      text-align: left; }
      .footer-copyright-right {
        padding: 0;
        float: right; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 16px;
  height: 100%; }

form,
body {
  font-size: 15px;
  font-size: 0.9375rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  color: #002b50; }

img {
  max-width: 100%;
  height: auto !important; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.pt-25 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 992px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 1200px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 1400px) {
    .pt-25 {
      padding-top: 25px; } }

.pb-25 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 1200px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 1400px) {
    .pb-25 {
      padding-bottom: 25px; } }

.mt-25 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 992px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 1200px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 1400px) {
    .mt-25 {
      margin-top: 25px; } }

.mb-25 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 1200px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 1400px) {
    .mb-25 {
      margin-bottom: 25px; } }

.pt-30 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 992px) {
    .pt-30 {
      padding-top: 21px; } }
  @media (min-width: 1200px) {
    .pt-30 {
      padding-top: 24px; } }
  @media (min-width: 1400px) {
    .pt-30 {
      padding-top: 30px; } }

.pb-30 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .pb-30 {
      padding-bottom: 21px; } }
  @media (min-width: 1200px) {
    .pb-30 {
      padding-bottom: 24px; } }
  @media (min-width: 1400px) {
    .pb-30 {
      padding-bottom: 30px; } }

.mt-30 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 992px) {
    .mt-30 {
      margin-top: 21px; } }
  @media (min-width: 1200px) {
    .mt-30 {
      margin-top: 24px; } }
  @media (min-width: 1400px) {
    .mt-30 {
      margin-top: 30px; } }

.mb-30 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .mb-30 {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    .mb-30 {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    .mb-30 {
      margin-bottom: 30px; } }

.pt-35 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-35 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-35 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-35 {
      padding-top: 21px; } }
  @media (min-width: 992px) {
    .pt-35 {
      padding-top: 25px; } }
  @media (min-width: 1200px) {
    .pt-35 {
      padding-top: 28px; } }
  @media (min-width: 1400px) {
    .pt-35 {
      padding-top: 35px; } }

.pb-35 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-35 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-35 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-35 {
      padding-bottom: 21px; } }
  @media (min-width: 992px) {
    .pb-35 {
      padding-bottom: 25px; } }
  @media (min-width: 1200px) {
    .pb-35 {
      padding-bottom: 28px; } }
  @media (min-width: 1400px) {
    .pb-35 {
      padding-bottom: 35px; } }

.mt-35 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-35 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-35 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-35 {
      margin-top: 21px; } }
  @media (min-width: 992px) {
    .mt-35 {
      margin-top: 25px; } }
  @media (min-width: 1200px) {
    .mt-35 {
      margin-top: 28px; } }
  @media (min-width: 1400px) {
    .mt-35 {
      margin-top: 35px; } }

.mb-35 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-35 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-35 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-35 {
      margin-bottom: 21px; } }
  @media (min-width: 992px) {
    .mb-35 {
      margin-bottom: 25px; } }
  @media (min-width: 1200px) {
    .mb-35 {
      margin-bottom: 28px; } }
  @media (min-width: 1400px) {
    .mb-35 {
      margin-bottom: 35px; } }

.pt-40 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-40 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-40 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-40 {
      padding-top: 24px; } }
  @media (min-width: 992px) {
    .pt-40 {
      padding-top: 28px; } }
  @media (min-width: 1200px) {
    .pt-40 {
      padding-top: 32px; } }
  @media (min-width: 1400px) {
    .pt-40 {
      padding-top: 40px; } }

.pb-40 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-40 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-40 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-40 {
      padding-bottom: 24px; } }
  @media (min-width: 992px) {
    .pb-40 {
      padding-bottom: 28px; } }
  @media (min-width: 1200px) {
    .pb-40 {
      padding-bottom: 32px; } }
  @media (min-width: 1400px) {
    .pb-40 {
      padding-bottom: 40px; } }

.mt-40 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-40 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-40 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-40 {
      margin-top: 24px; } }
  @media (min-width: 992px) {
    .mt-40 {
      margin-top: 28px; } }
  @media (min-width: 1200px) {
    .mt-40 {
      margin-top: 32px; } }
  @media (min-width: 1400px) {
    .mt-40 {
      margin-top: 40px; } }

.mb-40 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-40 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-40 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-40 {
      margin-bottom: 24px; } }
  @media (min-width: 992px) {
    .mb-40 {
      margin-bottom: 28px; } }
  @media (min-width: 1200px) {
    .mb-40 {
      margin-bottom: 32px; } }
  @media (min-width: 1400px) {
    .mb-40 {
      margin-bottom: 40px; } }

.pt-45 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-45 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-45 {
      padding-top: 23px; } }
  @media (min-width: 768px) {
    .pt-45 {
      padding-top: 27px; } }
  @media (min-width: 992px) {
    .pt-45 {
      padding-top: 32px; } }
  @media (min-width: 1200px) {
    .pt-45 {
      padding-top: 36px; } }
  @media (min-width: 1400px) {
    .pt-45 {
      padding-top: 45px; } }

.pb-45 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-45 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-45 {
      padding-bottom: 23px; } }
  @media (min-width: 768px) {
    .pb-45 {
      padding-bottom: 27px; } }
  @media (min-width: 992px) {
    .pb-45 {
      padding-bottom: 32px; } }
  @media (min-width: 1200px) {
    .pb-45 {
      padding-bottom: 36px; } }
  @media (min-width: 1400px) {
    .pb-45 {
      padding-bottom: 45px; } }

.mt-45 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-45 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-45 {
      margin-top: 23px; } }
  @media (min-width: 768px) {
    .mt-45 {
      margin-top: 27px; } }
  @media (min-width: 992px) {
    .mt-45 {
      margin-top: 32px; } }
  @media (min-width: 1200px) {
    .mt-45 {
      margin-top: 36px; } }
  @media (min-width: 1400px) {
    .mt-45 {
      margin-top: 45px; } }

.mb-45 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-45 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-45 {
      margin-bottom: 23px; } }
  @media (min-width: 768px) {
    .mb-45 {
      margin-bottom: 27px; } }
  @media (min-width: 992px) {
    .mb-45 {
      margin-bottom: 32px; } }
  @media (min-width: 1200px) {
    .mb-45 {
      margin-bottom: 36px; } }
  @media (min-width: 1400px) {
    .mb-45 {
      margin-bottom: 45px; } }

.pt-50 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-50 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-50 {
      padding-top: 25px; } }
  @media (min-width: 768px) {
    .pt-50 {
      padding-top: 30px; } }
  @media (min-width: 992px) {
    .pt-50 {
      padding-top: 35px; } }
  @media (min-width: 1200px) {
    .pt-50 {
      padding-top: 40px; } }
  @media (min-width: 1400px) {
    .pt-50 {
      padding-top: 50px; } }

.pb-50 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-50 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-50 {
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .pb-50 {
      padding-bottom: 30px; } }
  @media (min-width: 992px) {
    .pb-50 {
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .pb-50 {
      padding-bottom: 40px; } }
  @media (min-width: 1400px) {
    .pb-50 {
      padding-bottom: 50px; } }

.mt-50 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-50 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-50 {
      margin-top: 25px; } }
  @media (min-width: 768px) {
    .mt-50 {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .mt-50 {
      margin-top: 35px; } }
  @media (min-width: 1200px) {
    .mt-50 {
      margin-top: 40px; } }
  @media (min-width: 1400px) {
    .mt-50 {
      margin-top: 50px; } }

.mb-50 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-50 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-50 {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .mb-50 {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .mb-50 {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .mb-50 {
      margin-bottom: 40px; } }
  @media (min-width: 1400px) {
    .mb-50 {
      margin-bottom: 50px; } }

.pt-55 {
  padding-top: 22px; }
  @media (min-width: 480px) {
    .pt-55 {
      padding-top: 22px; } }
  @media (min-width: 545px) {
    .pt-55 {
      padding-top: 28px; } }
  @media (min-width: 768px) {
    .pt-55 {
      padding-top: 33px; } }
  @media (min-width: 992px) {
    .pt-55 {
      padding-top: 39px; } }
  @media (min-width: 1200px) {
    .pt-55 {
      padding-top: 44px; } }
  @media (min-width: 1400px) {
    .pt-55 {
      padding-top: 55px; } }

.pb-55 {
  padding-bottom: 22px; }
  @media (min-width: 480px) {
    .pb-55 {
      padding-bottom: 22px; } }
  @media (min-width: 545px) {
    .pb-55 {
      padding-bottom: 28px; } }
  @media (min-width: 768px) {
    .pb-55 {
      padding-bottom: 33px; } }
  @media (min-width: 992px) {
    .pb-55 {
      padding-bottom: 39px; } }
  @media (min-width: 1200px) {
    .pb-55 {
      padding-bottom: 44px; } }
  @media (min-width: 1400px) {
    .pb-55 {
      padding-bottom: 55px; } }

.mt-55 {
  margin-top: 22px; }
  @media (min-width: 480px) {
    .mt-55 {
      margin-top: 22px; } }
  @media (min-width: 545px) {
    .mt-55 {
      margin-top: 28px; } }
  @media (min-width: 768px) {
    .mt-55 {
      margin-top: 33px; } }
  @media (min-width: 992px) {
    .mt-55 {
      margin-top: 39px; } }
  @media (min-width: 1200px) {
    .mt-55 {
      margin-top: 44px; } }
  @media (min-width: 1400px) {
    .mt-55 {
      margin-top: 55px; } }

.mb-55 {
  margin-bottom: 22px; }
  @media (min-width: 480px) {
    .mb-55 {
      margin-bottom: 22px; } }
  @media (min-width: 545px) {
    .mb-55 {
      margin-bottom: 28px; } }
  @media (min-width: 768px) {
    .mb-55 {
      margin-bottom: 33px; } }
  @media (min-width: 992px) {
    .mb-55 {
      margin-bottom: 39px; } }
  @media (min-width: 1200px) {
    .mb-55 {
      margin-bottom: 44px; } }
  @media (min-width: 1400px) {
    .mb-55 {
      margin-bottom: 55px; } }

.pt-60 {
  padding-top: 24px; }
  @media (min-width: 480px) {
    .pt-60 {
      padding-top: 24px; } }
  @media (min-width: 545px) {
    .pt-60 {
      padding-top: 30px; } }
  @media (min-width: 768px) {
    .pt-60 {
      padding-top: 36px; } }
  @media (min-width: 992px) {
    .pt-60 {
      padding-top: 42px; } }
  @media (min-width: 1200px) {
    .pt-60 {
      padding-top: 48px; } }
  @media (min-width: 1400px) {
    .pt-60 {
      padding-top: 60px; } }

.pb-60 {
  padding-bottom: 24px; }
  @media (min-width: 480px) {
    .pb-60 {
      padding-bottom: 24px; } }
  @media (min-width: 545px) {
    .pb-60 {
      padding-bottom: 30px; } }
  @media (min-width: 768px) {
    .pb-60 {
      padding-bottom: 36px; } }
  @media (min-width: 992px) {
    .pb-60 {
      padding-bottom: 42px; } }
  @media (min-width: 1200px) {
    .pb-60 {
      padding-bottom: 48px; } }
  @media (min-width: 1400px) {
    .pb-60 {
      padding-bottom: 60px; } }

.mt-60 {
  margin-top: 24px; }
  @media (min-width: 480px) {
    .mt-60 {
      margin-top: 24px; } }
  @media (min-width: 545px) {
    .mt-60 {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .mt-60 {
      margin-top: 36px; } }
  @media (min-width: 992px) {
    .mt-60 {
      margin-top: 42px; } }
  @media (min-width: 1200px) {
    .mt-60 {
      margin-top: 48px; } }
  @media (min-width: 1400px) {
    .mt-60 {
      margin-top: 60px; } }

.mb-60 {
  margin-bottom: 24px; }
  @media (min-width: 480px) {
    .mb-60 {
      margin-bottom: 24px; } }
  @media (min-width: 545px) {
    .mb-60 {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .mb-60 {
      margin-bottom: 36px; } }
  @media (min-width: 992px) {
    .mb-60 {
      margin-bottom: 42px; } }
  @media (min-width: 1200px) {
    .mb-60 {
      margin-bottom: 48px; } }
  @media (min-width: 1400px) {
    .mb-60 {
      margin-bottom: 60px; } }

.pt-65 {
  padding-top: 26px; }
  @media (min-width: 480px) {
    .pt-65 {
      padding-top: 26px; } }
  @media (min-width: 545px) {
    .pt-65 {
      padding-top: 33px; } }
  @media (min-width: 768px) {
    .pt-65 {
      padding-top: 39px; } }
  @media (min-width: 992px) {
    .pt-65 {
      padding-top: 46px; } }
  @media (min-width: 1200px) {
    .pt-65 {
      padding-top: 52px; } }
  @media (min-width: 1400px) {
    .pt-65 {
      padding-top: 65px; } }

.pb-65 {
  padding-bottom: 26px; }
  @media (min-width: 480px) {
    .pb-65 {
      padding-bottom: 26px; } }
  @media (min-width: 545px) {
    .pb-65 {
      padding-bottom: 33px; } }
  @media (min-width: 768px) {
    .pb-65 {
      padding-bottom: 39px; } }
  @media (min-width: 992px) {
    .pb-65 {
      padding-bottom: 46px; } }
  @media (min-width: 1200px) {
    .pb-65 {
      padding-bottom: 52px; } }
  @media (min-width: 1400px) {
    .pb-65 {
      padding-bottom: 65px; } }

.mt-65 {
  margin-top: 26px; }
  @media (min-width: 480px) {
    .mt-65 {
      margin-top: 26px; } }
  @media (min-width: 545px) {
    .mt-65 {
      margin-top: 33px; } }
  @media (min-width: 768px) {
    .mt-65 {
      margin-top: 39px; } }
  @media (min-width: 992px) {
    .mt-65 {
      margin-top: 46px; } }
  @media (min-width: 1200px) {
    .mt-65 {
      margin-top: 52px; } }
  @media (min-width: 1400px) {
    .mt-65 {
      margin-top: 65px; } }

.mb-65 {
  margin-bottom: 26px; }
  @media (min-width: 480px) {
    .mb-65 {
      margin-bottom: 26px; } }
  @media (min-width: 545px) {
    .mb-65 {
      margin-bottom: 33px; } }
  @media (min-width: 768px) {
    .mb-65 {
      margin-bottom: 39px; } }
  @media (min-width: 992px) {
    .mb-65 {
      margin-bottom: 46px; } }
  @media (min-width: 1200px) {
    .mb-65 {
      margin-bottom: 52px; } }
  @media (min-width: 1400px) {
    .mb-65 {
      margin-bottom: 65px; } }

.pt-70 {
  padding-top: 28px; }
  @media (min-width: 480px) {
    .pt-70 {
      padding-top: 28px; } }
  @media (min-width: 545px) {
    .pt-70 {
      padding-top: 35px; } }
  @media (min-width: 768px) {
    .pt-70 {
      padding-top: 42px; } }
  @media (min-width: 992px) {
    .pt-70 {
      padding-top: 49px; } }
  @media (min-width: 1200px) {
    .pt-70 {
      padding-top: 56px; } }
  @media (min-width: 1400px) {
    .pt-70 {
      padding-top: 70px; } }

.pb-70 {
  padding-bottom: 28px; }
  @media (min-width: 480px) {
    .pb-70 {
      padding-bottom: 28px; } }
  @media (min-width: 545px) {
    .pb-70 {
      padding-bottom: 35px; } }
  @media (min-width: 768px) {
    .pb-70 {
      padding-bottom: 42px; } }
  @media (min-width: 992px) {
    .pb-70 {
      padding-bottom: 49px; } }
  @media (min-width: 1200px) {
    .pb-70 {
      padding-bottom: 56px; } }
  @media (min-width: 1400px) {
    .pb-70 {
      padding-bottom: 70px; } }

.mt-70 {
  margin-top: 28px; }
  @media (min-width: 480px) {
    .mt-70 {
      margin-top: 28px; } }
  @media (min-width: 545px) {
    .mt-70 {
      margin-top: 35px; } }
  @media (min-width: 768px) {
    .mt-70 {
      margin-top: 42px; } }
  @media (min-width: 992px) {
    .mt-70 {
      margin-top: 49px; } }
  @media (min-width: 1200px) {
    .mt-70 {
      margin-top: 56px; } }
  @media (min-width: 1400px) {
    .mt-70 {
      margin-top: 70px; } }

.mb-70 {
  margin-bottom: 28px; }
  @media (min-width: 480px) {
    .mb-70 {
      margin-bottom: 28px; } }
  @media (min-width: 545px) {
    .mb-70 {
      margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .mb-70 {
      margin-bottom: 42px; } }
  @media (min-width: 992px) {
    .mb-70 {
      margin-bottom: 49px; } }
  @media (min-width: 1200px) {
    .mb-70 {
      margin-bottom: 56px; } }
  @media (min-width: 1400px) {
    .mb-70 {
      margin-bottom: 70px; } }

.pt-75 {
  padding-top: 30px; }
  @media (min-width: 480px) {
    .pt-75 {
      padding-top: 30px; } }
  @media (min-width: 545px) {
    .pt-75 {
      padding-top: 38px; } }
  @media (min-width: 768px) {
    .pt-75 {
      padding-top: 45px; } }
  @media (min-width: 992px) {
    .pt-75 {
      padding-top: 53px; } }
  @media (min-width: 1200px) {
    .pt-75 {
      padding-top: 60px; } }
  @media (min-width: 1400px) {
    .pt-75 {
      padding-top: 75px; } }

.pb-75 {
  padding-bottom: 30px; }
  @media (min-width: 480px) {
    .pb-75 {
      padding-bottom: 30px; } }
  @media (min-width: 545px) {
    .pb-75 {
      padding-bottom: 38px; } }
  @media (min-width: 768px) {
    .pb-75 {
      padding-bottom: 45px; } }
  @media (min-width: 992px) {
    .pb-75 {
      padding-bottom: 53px; } }
  @media (min-width: 1200px) {
    .pb-75 {
      padding-bottom: 60px; } }
  @media (min-width: 1400px) {
    .pb-75 {
      padding-bottom: 75px; } }

.mt-75 {
  margin-top: 30px; }
  @media (min-width: 480px) {
    .mt-75 {
      margin-top: 30px; } }
  @media (min-width: 545px) {
    .mt-75 {
      margin-top: 38px; } }
  @media (min-width: 768px) {
    .mt-75 {
      margin-top: 45px; } }
  @media (min-width: 992px) {
    .mt-75 {
      margin-top: 53px; } }
  @media (min-width: 1200px) {
    .mt-75 {
      margin-top: 60px; } }
  @media (min-width: 1400px) {
    .mt-75 {
      margin-top: 75px; } }

.mb-75 {
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .mb-75 {
      margin-bottom: 30px; } }
  @media (min-width: 545px) {
    .mb-75 {
      margin-bottom: 38px; } }
  @media (min-width: 768px) {
    .mb-75 {
      margin-bottom: 45px; } }
  @media (min-width: 992px) {
    .mb-75 {
      margin-bottom: 53px; } }
  @media (min-width: 1200px) {
    .mb-75 {
      margin-bottom: 60px; } }
  @media (min-width: 1400px) {
    .mb-75 {
      margin-bottom: 75px; } }

.pt-80 {
  padding-top: 32px; }
  @media (min-width: 480px) {
    .pt-80 {
      padding-top: 32px; } }
  @media (min-width: 545px) {
    .pt-80 {
      padding-top: 40px; } }
  @media (min-width: 768px) {
    .pt-80 {
      padding-top: 48px; } }
  @media (min-width: 992px) {
    .pt-80 {
      padding-top: 56px; } }
  @media (min-width: 1200px) {
    .pt-80 {
      padding-top: 64px; } }
  @media (min-width: 1400px) {
    .pt-80 {
      padding-top: 80px; } }

.pb-80 {
  padding-bottom: 32px; }
  @media (min-width: 480px) {
    .pb-80 {
      padding-bottom: 32px; } }
  @media (min-width: 545px) {
    .pb-80 {
      padding-bottom: 40px; } }
  @media (min-width: 768px) {
    .pb-80 {
      padding-bottom: 48px; } }
  @media (min-width: 992px) {
    .pb-80 {
      padding-bottom: 56px; } }
  @media (min-width: 1200px) {
    .pb-80 {
      padding-bottom: 64px; } }
  @media (min-width: 1400px) {
    .pb-80 {
      padding-bottom: 80px; } }

.mt-80 {
  margin-top: 32px; }
  @media (min-width: 480px) {
    .mt-80 {
      margin-top: 32px; } }
  @media (min-width: 545px) {
    .mt-80 {
      margin-top: 40px; } }
  @media (min-width: 768px) {
    .mt-80 {
      margin-top: 48px; } }
  @media (min-width: 992px) {
    .mt-80 {
      margin-top: 56px; } }
  @media (min-width: 1200px) {
    .mt-80 {
      margin-top: 64px; } }
  @media (min-width: 1400px) {
    .mt-80 {
      margin-top: 80px; } }

.mb-80 {
  margin-bottom: 32px; }
  @media (min-width: 480px) {
    .mb-80 {
      margin-bottom: 32px; } }
  @media (min-width: 545px) {
    .mb-80 {
      margin-bottom: 40px; } }
  @media (min-width: 768px) {
    .mb-80 {
      margin-bottom: 48px; } }
  @media (min-width: 992px) {
    .mb-80 {
      margin-bottom: 56px; } }
  @media (min-width: 1200px) {
    .mb-80 {
      margin-bottom: 64px; } }
  @media (min-width: 1400px) {
    .mb-80 {
      margin-bottom: 80px; } }

.pt-85 {
  padding-top: 34px; }
  @media (min-width: 480px) {
    .pt-85 {
      padding-top: 34px; } }
  @media (min-width: 545px) {
    .pt-85 {
      padding-top: 43px; } }
  @media (min-width: 768px) {
    .pt-85 {
      padding-top: 51px; } }
  @media (min-width: 992px) {
    .pt-85 {
      padding-top: 60px; } }
  @media (min-width: 1200px) {
    .pt-85 {
      padding-top: 68px; } }
  @media (min-width: 1400px) {
    .pt-85 {
      padding-top: 85px; } }

.pb-85 {
  padding-bottom: 34px; }
  @media (min-width: 480px) {
    .pb-85 {
      padding-bottom: 34px; } }
  @media (min-width: 545px) {
    .pb-85 {
      padding-bottom: 43px; } }
  @media (min-width: 768px) {
    .pb-85 {
      padding-bottom: 51px; } }
  @media (min-width: 992px) {
    .pb-85 {
      padding-bottom: 60px; } }
  @media (min-width: 1200px) {
    .pb-85 {
      padding-bottom: 68px; } }
  @media (min-width: 1400px) {
    .pb-85 {
      padding-bottom: 85px; } }

.mt-85 {
  margin-top: 34px; }
  @media (min-width: 480px) {
    .mt-85 {
      margin-top: 34px; } }
  @media (min-width: 545px) {
    .mt-85 {
      margin-top: 43px; } }
  @media (min-width: 768px) {
    .mt-85 {
      margin-top: 51px; } }
  @media (min-width: 992px) {
    .mt-85 {
      margin-top: 60px; } }
  @media (min-width: 1200px) {
    .mt-85 {
      margin-top: 68px; } }
  @media (min-width: 1400px) {
    .mt-85 {
      margin-top: 85px; } }

.mb-85 {
  margin-bottom: 34px; }
  @media (min-width: 480px) {
    .mb-85 {
      margin-bottom: 34px; } }
  @media (min-width: 545px) {
    .mb-85 {
      margin-bottom: 43px; } }
  @media (min-width: 768px) {
    .mb-85 {
      margin-bottom: 51px; } }
  @media (min-width: 992px) {
    .mb-85 {
      margin-bottom: 60px; } }
  @media (min-width: 1200px) {
    .mb-85 {
      margin-bottom: 68px; } }
  @media (min-width: 1400px) {
    .mb-85 {
      margin-bottom: 85px; } }

.pt-90 {
  padding-top: 36px; }
  @media (min-width: 480px) {
    .pt-90 {
      padding-top: 36px; } }
  @media (min-width: 545px) {
    .pt-90 {
      padding-top: 45px; } }
  @media (min-width: 768px) {
    .pt-90 {
      padding-top: 54px; } }
  @media (min-width: 992px) {
    .pt-90 {
      padding-top: 63px; } }
  @media (min-width: 1200px) {
    .pt-90 {
      padding-top: 72px; } }
  @media (min-width: 1400px) {
    .pt-90 {
      padding-top: 90px; } }

.pb-90 {
  padding-bottom: 36px; }
  @media (min-width: 480px) {
    .pb-90 {
      padding-bottom: 36px; } }
  @media (min-width: 545px) {
    .pb-90 {
      padding-bottom: 45px; } }
  @media (min-width: 768px) {
    .pb-90 {
      padding-bottom: 54px; } }
  @media (min-width: 992px) {
    .pb-90 {
      padding-bottom: 63px; } }
  @media (min-width: 1200px) {
    .pb-90 {
      padding-bottom: 72px; } }
  @media (min-width: 1400px) {
    .pb-90 {
      padding-bottom: 90px; } }

.mt-90 {
  margin-top: 36px; }
  @media (min-width: 480px) {
    .mt-90 {
      margin-top: 36px; } }
  @media (min-width: 545px) {
    .mt-90 {
      margin-top: 45px; } }
  @media (min-width: 768px) {
    .mt-90 {
      margin-top: 54px; } }
  @media (min-width: 992px) {
    .mt-90 {
      margin-top: 63px; } }
  @media (min-width: 1200px) {
    .mt-90 {
      margin-top: 72px; } }
  @media (min-width: 1400px) {
    .mt-90 {
      margin-top: 90px; } }

.mb-90 {
  margin-bottom: 36px; }
  @media (min-width: 480px) {
    .mb-90 {
      margin-bottom: 36px; } }
  @media (min-width: 545px) {
    .mb-90 {
      margin-bottom: 45px; } }
  @media (min-width: 768px) {
    .mb-90 {
      margin-bottom: 54px; } }
  @media (min-width: 992px) {
    .mb-90 {
      margin-bottom: 63px; } }
  @media (min-width: 1200px) {
    .mb-90 {
      margin-bottom: 72px; } }
  @media (min-width: 1400px) {
    .mb-90 {
      margin-bottom: 90px; } }

.pt-95 {
  padding-top: 38px; }
  @media (min-width: 480px) {
    .pt-95 {
      padding-top: 38px; } }
  @media (min-width: 545px) {
    .pt-95 {
      padding-top: 48px; } }
  @media (min-width: 768px) {
    .pt-95 {
      padding-top: 57px; } }
  @media (min-width: 992px) {
    .pt-95 {
      padding-top: 67px; } }
  @media (min-width: 1200px) {
    .pt-95 {
      padding-top: 76px; } }
  @media (min-width: 1400px) {
    .pt-95 {
      padding-top: 95px; } }

.pb-95 {
  padding-bottom: 38px; }
  @media (min-width: 480px) {
    .pb-95 {
      padding-bottom: 38px; } }
  @media (min-width: 545px) {
    .pb-95 {
      padding-bottom: 48px; } }
  @media (min-width: 768px) {
    .pb-95 {
      padding-bottom: 57px; } }
  @media (min-width: 992px) {
    .pb-95 {
      padding-bottom: 67px; } }
  @media (min-width: 1200px) {
    .pb-95 {
      padding-bottom: 76px; } }
  @media (min-width: 1400px) {
    .pb-95 {
      padding-bottom: 95px; } }

.mt-95 {
  margin-top: 38px; }
  @media (min-width: 480px) {
    .mt-95 {
      margin-top: 38px; } }
  @media (min-width: 545px) {
    .mt-95 {
      margin-top: 48px; } }
  @media (min-width: 768px) {
    .mt-95 {
      margin-top: 57px; } }
  @media (min-width: 992px) {
    .mt-95 {
      margin-top: 67px; } }
  @media (min-width: 1200px) {
    .mt-95 {
      margin-top: 76px; } }
  @media (min-width: 1400px) {
    .mt-95 {
      margin-top: 95px; } }

.mb-95 {
  margin-bottom: 38px; }
  @media (min-width: 480px) {
    .mb-95 {
      margin-bottom: 38px; } }
  @media (min-width: 545px) {
    .mb-95 {
      margin-bottom: 48px; } }
  @media (min-width: 768px) {
    .mb-95 {
      margin-bottom: 57px; } }
  @media (min-width: 992px) {
    .mb-95 {
      margin-bottom: 67px; } }
  @media (min-width: 1200px) {
    .mb-95 {
      margin-bottom: 76px; } }
  @media (min-width: 1400px) {
    .mb-95 {
      margin-bottom: 95px; } }

.pt-100 {
  padding-top: 40px; }
  @media (min-width: 480px) {
    .pt-100 {
      padding-top: 40px; } }
  @media (min-width: 545px) {
    .pt-100 {
      padding-top: 50px; } }
  @media (min-width: 768px) {
    .pt-100 {
      padding-top: 60px; } }
  @media (min-width: 992px) {
    .pt-100 {
      padding-top: 70px; } }
  @media (min-width: 1200px) {
    .pt-100 {
      padding-top: 80px; } }
  @media (min-width: 1400px) {
    .pt-100 {
      padding-top: 100px; } }

.pb-100 {
  padding-bottom: 40px; }
  @media (min-width: 480px) {
    .pb-100 {
      padding-bottom: 40px; } }
  @media (min-width: 545px) {
    .pb-100 {
      padding-bottom: 50px; } }
  @media (min-width: 768px) {
    .pb-100 {
      padding-bottom: 60px; } }
  @media (min-width: 992px) {
    .pb-100 {
      padding-bottom: 70px; } }
  @media (min-width: 1200px) {
    .pb-100 {
      padding-bottom: 80px; } }
  @media (min-width: 1400px) {
    .pb-100 {
      padding-bottom: 100px; } }

.mt-100 {
  margin-top: 40px; }
  @media (min-width: 480px) {
    .mt-100 {
      margin-top: 40px; } }
  @media (min-width: 545px) {
    .mt-100 {
      margin-top: 50px; } }
  @media (min-width: 768px) {
    .mt-100 {
      margin-top: 60px; } }
  @media (min-width: 992px) {
    .mt-100 {
      margin-top: 70px; } }
  @media (min-width: 1200px) {
    .mt-100 {
      margin-top: 80px; } }
  @media (min-width: 1400px) {
    .mt-100 {
      margin-top: 100px; } }

.mb-100 {
  margin-bottom: 40px; }
  @media (min-width: 480px) {
    .mb-100 {
      margin-bottom: 40px; } }
  @media (min-width: 545px) {
    .mb-100 {
      margin-bottom: 50px; } }
  @media (min-width: 768px) {
    .mb-100 {
      margin-bottom: 60px; } }
  @media (min-width: 992px) {
    .mb-100 {
      margin-bottom: 70px; } }
  @media (min-width: 1200px) {
    .mb-100 {
      margin-bottom: 80px; } }
  @media (min-width: 1400px) {
    .mb-100 {
      margin-bottom: 100px; } }

h1,
.h1 {
  font-weight: 700;
  color: #002b50;
  margin: 0px 0px 28px 0px;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 40px;
  line-height: 2.5rem; }
  @media (min-width: 480px) {
    h1,
    .h1 {
      margin: 0px 0px 28px 0px; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      margin: 0px 0px 28px 0px; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      margin: 0px 0px 28px 0px; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      margin: 0px 0px 28px 0px; } }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      margin: 0px 0px 32px 0px; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      margin: 0px 0px 40px 0px; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      font-size: 29px;
      font-size: 1.8125rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 48px;
      line-height: 3rem; } }

h2,
.h2 {
  font-weight: 700;
  color: #002b50;
  margin: 0px 0px 22px 0px;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  line-height: 2rem; }
  @media (min-width: 480px) {
    h2,
    .h2 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      margin: 0px 0px 23px 0px; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      margin: 0px 0px 26px 0px; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      margin: 0px 0px 32px 0px; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }

h3,
.h3 {
  font-weight: 400;
  color: #002b50;
  margin: 0px 0px 22px 0px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 32px;
  line-height: 2rem; }
  @media (min-width: 480px) {
    h3,
    .h3 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      margin: 0px 0px 22px 0px; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      margin: 0px 0px 23px 0px; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      margin: 0px 0px 26px 0px; } }
  @media (min-width: 1400px) {
    h3,
    .h3 {
      margin: 0px 0px 32px 0px; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 23px;
      font-size: 1.4375rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 1400px) {
    h3,
    .h3 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      line-height: 2.5rem; } }

h4,
.h4 {
  font-weight: 700;
  color: #002b50;
  font-size: 16px;
  font-size: 1rem;
  line-height: 28px;
  line-height: 1.75rem;
  margin: 0px 0px 15px 0px; }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 30px;
      line-height: 1.875rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      margin: 0px 0px 15px 0px; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      margin: 0px 0px 15px 0px; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      margin: 0px 0px 15px 0px; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      margin: 0px 0px 15px 0px; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      margin: 0px 0px 16px 0px; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      margin: 0px 0px 20px 0px; } }

big,
.perex {
  color: #131313;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 19px;
  line-height: 1.1875rem; }
  @media (min-width: 480px) {
    big,
    .perex {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 1200px) {
    big,
    .perex {
      font-size: 16px;
      font-size: 1rem;
      line-height: 21px;
      line-height: 1.3125rem; } }
  @media (min-width: 1400px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 26px;
      line-height: 1.625rem; } }

small,
.text-small {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 19px;
  line-height: 1.1875rem; }
  @media (min-width: 480px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 545px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 768px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 992px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 19px;
      line-height: 1.1875rem; } }
  @media (min-width: 1200px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 20px;
      line-height: 1.25rem; } }
  @media (min-width: 1400px) {
    small,
    .text-small {
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 24px;
      line-height: 1.5rem; } }

a {
  color: #005ba9;
  cursor: pointer; }
  a:focus, a:hover {
    outline: none;
    color: #84cbe9;
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 13px 40px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer; }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: #005ba9;
  color: #fff; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #004d90;
    color: #fff; }

.btn-default {
  background: #002b50;
  color: #fff; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #001d37;
    color: #fff; }

.btn-white {
  background: #fff;
  color: #005ba9; }
  .btn-white:focus, .btn-white:hover {
    text-decoration: none;
    background: #84cbe9;
    color: #005ba9; }

.btn-arrow:after {
  content: '\e90c';
  font-family: 'icommon';
  margin: 0 0 0 10px;
  font-size: 10px;
  font-size: 0.625rem;
  -moz-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  -webkit-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s; }

.btn-arrow:hover {
  text-decoration: none; }
  .btn-arrow:hover:after {
    margin-left: 13px; }

.btn-back {
  display: block;
  text-align: right;
  margin: 0 0 30px;
  font-size: 16px;
  font-size: 1rem; }
  .btn-back:before {
    content: '\e90c';
    font-family: 'icommon';
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 10px 0 0;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    font-size: 10px;
    font-size: 0.625rem;
    -moz-transition: all ease-in-out 0.1s;
    -o-transition: all ease-in-out 0.1s;
    -webkit-transition: all ease-in-out 0.1s;
    transition: all ease-in-out 0.1s; }
  .btn-back:hover {
    text-decoration: none; }
    .btn-back:hover:before {
      margin-right: 13px; }
  @media (min-width: 545px) {
    .btn-back {
      float: right; } }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 400px; }
  main ol {
    color: #131313;
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 10px 0;
      padding: 0 0 0 20px; }
      main ol li:before {
        font-family: inherit !important;
        content: counter(my-ol);
        counter-increment: my-ol;
        color: #131313;
        position: absolute;
        left: 0;
        top: 4px;
        font-weight: 400; }
  main ul {
    color: #131313;
    margin: 0 0 25px;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 10px 0;
      padding: 0 0 0 15px;
      position: relative; }
      main ul li:before {
        content: "";
        position: absolute;
        top: 9px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 4px solid #131313; }
  main table {
    color: #131313;
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 10px 15px; }
      main table tr:nth-child(even) {
        background: #fff; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 10px 15px; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 20px 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #fff; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Roboto", sans-serif !important;
          margin: 0 15px 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

label {
  display: block;
  margin: 10px 0 5px 0;
  font-size: 14px;
  font-size: 0.875rem; }

.form-control,
textarea,
input[type="text"],
input[type="password"] {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #e6e6e6;
  background: #fff;
  color: #002b50;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px; }
  .form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #a7a7a7; }
  .form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #a7a7a7; }
  .form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #a7a7a7; }
  .form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #a7a7a7; }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #005ba9; }

textarea {
  height: 135px;
  padding: 15px; }
  textarea:focus {
    border: 1px solid #005ba9; }

input[type="submit"] {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 45px;
  background: #005ba9;
  color: #fff;
  border: none;
  margin: 25px 0 0;
  cursor: pointer; }
  input[type="submit"]:focus, input[type="submit"]:hover {
    background: #003987;
    color: #fff; }

.required label:before {
  content: "*";
  font-size: 10px;
  display: inline-block;
  vertical-align: 6px;
  margin: 0 2px 0 0; }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  background: #005ba9;
  cursor: pointer;
  font-size: 15px;
  font-size: 0.9375rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 240px;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }

.file .file-name {
  padding: 5px;
  background: #002b50;
  color: #131313; }

.Error select,
.Error .selectric,
.Error input[type="text"],
.Error textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.EditingFormErrorLabel {
  font-size: 14px;
  font-size: 0.875rem;
  color: #d40511;
  display: block;
  margin: 5px 0 0 0; }

.ErrorLabel {
  color: #d40511;
  display: block;
  margin: 5px 10px; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 10px 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #005ba9;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #005ba9; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 10px 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #005ba9;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #005ba9; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.form {
  padding: 20px 25px;
  background: #f6f6f6; }
  .form:after {
    content: '';
    display: table;
    clear: both; }
  .form-title {
    font-weight: 400;
    color: #002b50;
    margin: 0px 0px 22px 0px;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 32px;
    line-height: 2rem; }
    @media (min-width: 480px) {
      .form-title {
        margin: 0px 0px 22px 0px; } }
    @media (min-width: 545px) {
      .form-title {
        margin: 0px 0px 22px 0px; } }
    @media (min-width: 768px) {
      .form-title {
        margin: 0px 0px 22px 0px; } }
    @media (min-width: 992px) {
      .form-title {
        margin: 0px 0px 23px 0px; } }
    @media (min-width: 1200px) {
      .form-title {
        margin: 0px 0px 26px 0px; } }
    @media (min-width: 1400px) {
      .form-title {
        margin: 0px 0px 32px 0px; } }
    @media (min-width: 480px) {
      .form-title {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 545px) {
      .form-title {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 768px) {
      .form-title {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 992px) {
      .form-title {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 1200px) {
      .form-title {
        font-size: 23px;
        font-size: 1.4375rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 1400px) {
      .form-title {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        line-height: 2.5rem; } }
  @media (min-width: 768px) {
    .form {
      padding: 25px 30px; } }

.product ul, .product ol {
  color: #002b50; }

.product-frame {
  background: #f6f6f6;
  padding: 15px;
  margin: 30px 0; }
  .product-frame strong {
    font-weight: 700;
    color: #002b50;
    float: left;
    padding: 10px 0 0;
    font-size: 18px;
    font-size: 1.125rem; }
  .product-frame-color {
    margin-top: -10px; }
    .product-frame-color ul {
      clear: both;
      font-size: 0;
      margin: 0 -20px 0 0;
      padding: 0; }
      .product-frame-color ul li {
        display: inline-block;
        padding: 0;
        width: 20%;
        margin: 15px 0 0; }
        .product-frame-color ul li:before {
          display: none; }
        .product-frame-color ul li a {
          display: block;
          width: 30px;
          height: 30px;
          border: 2px solid #fff;
          overflow: hidden;
          text-indent: 40px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%; }
          .product-frame-color ul li a:hover {
            border: 2px solid #002b50; }
        .product-frame-color ul li.active a {
          border: 3px solid #002b50; }
  .product-frame-price {
    text-align: right;
    font-weight: 700;
    color: #005ba9;
    font-size: 28px;
    font-size: 1.75rem; }
  .product-frame hr {
    margin: 20px -15px;
    border-top: 2px solid #fff; }

.product-download ul li {
  padding: 0; }
  .product-download ul li:before {
    display: none; }
  .product-download ul li a {
    display: block; }
    .product-download ul li a:before {
      content: '\e93a';
      display: inline-block;
      vertical-align: middle;
      font-family: 'icomoon';
      margin: 0 20px 0 0;
      font-size: 36px;
      font-size: 2.25rem; }

@media (min-width: 545px) {
  .product-frame {
    padding: 30px; }
    .product-frame-color ul {
      margin: 0 -30px 0 0; }
    .product-frame hr {
      margin: 25px -30px; } }

@media (min-width: 1200px) {
  .product-frame {
    margin: 40px 0 50px; }
    .product-frame-color ul li {
      width: 10%; }
  .product-download ul li {
    float: left;
    width: 50%; } }

.product-list .items-container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.product-list .show-more-container {
  text-align: center;
  padding: 40px 0 20px; }
  .product-list .show-more-container .arrow-down-link {
    display: inline-block;
    padding: 13px 40px;
    background: #005ba9;
    color: #fff; }
    .product-list .show-more-container .arrow-down-link:focus, .product-list .show-more-container .arrow-down-link:hover {
      text-decoration: none;
      background: #004d90;
      color: #fff; }

.product-list .product-item {
  display: block;
  padding: 25px;
  background: #fff;
  display: block;
  border: 2px solid transparent;
  height: 100%; }
  .product-list .product-item-image {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    height: 220px;
    margin: 0 0 15px;
    -webkit-background-size: contain !important;
    background-size: contain !important; }
  .product-list .product-item-title {
    display: block;
    font-weight: 700;
    margin: 0 0 15px;
    color: #005ba9;
    font-size: 22px;
    font-size: 1.375rem; }
  .product-list .product-item-label {
    color: #131313; }
    .product-list .product-item-label > *:last-child {
      margin-bottom: 0 !important; }
  .product-list .product-item:hover, .product-list .product-item:focus {
    border-color: #005ba9;
    color: inherit; }

.product-tab:after {
  content: '';
  display: table;
  clear: both; }

.product-tab-item {
  display: block;
  background: #005ba9;
  padding: 15px;
  color: #fff;
  height: 100%;
  margin: 0 0 10px; }
  .product-tab-item:after {
    content: '';
    display: table;
    clear: both; }
  .product-tab-item-image {
    display: none;
    vertical-align: middle;
    width: 80px;
    margin: 0 10px 0 0;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain !important;
    background-size: contain !important; }
    .product-tab-item-image:before {
      content: '';
      display: block;
      padding: 100% 0 0; }
  .product-tab-item-label {
    font-weight: 400; }
  .product-tab-item:hover, .product-tab-item:focus {
    background: #002b50;
    text-decoration: none;
    color: #fff; }
  .product-tab-item.active {
    background: #dadada;
    color: #005ba9; }

@media (min-width: 545px) {
  .product-tab-item-image {
    display: inline-block; } }

@media (min-width: 768px) {
  .product-tab-item {
    text-align: center; }
    .product-tab-item-image {
      display: block;
      width: auto;
      margin: 0 10% 20px; } }

.product-filter {
  padding: 40px 0 40px; }
  .product-filter ul li {
    display: block; }
    .product-filter ul li:before {
      display: none; }
    .product-filter ul li input[type="checkbox"] {
      display: none; }
      .product-filter ul li input[type="checkbox"] + label {
        position: relative;
        padding: 0 0 10px 25px;
        margin: 0;
        display: inline-block;
        cursor: pointer; }
        .product-filter ul li input[type="checkbox"] + label:before {
          content: '\e947';
          display: inline-block;
          vertical-align: middle;
          font-family: 'icomoon';
          margin: 0 8px 0 0;
          color: #005ba9;
          font-size: 18px;
          font-size: 1.125rem; }
      .product-filter ul li input[type="checkbox"]:checked + label:before {
        content: '\e948'; }
    .product-filter ul li a {
      color: #002b50; }
      .product-filter ul li a:before {
        content: '\e947';
        display: inline-block;
        vertical-align: middle;
        font-family: 'icomoon';
        margin: 0 8px 0 0;
        color: #005ba9;
        font-size: 18px;
        font-size: 1.125rem; }
      .product-filter ul li a:hover:before {
        color: #002b50; }
    .product-filter ul li .ico-tooltip {
      float: right; }
    .product-filter ul li.active a:before {
      content: '\e948'; }
  @media (min-width: 480px) {
    .product-filter ul li {
      display: inline-block;
      width: 48%; }
      .product-filter ul li .ico-tooltip {
        float: none; } }
  @media (min-width: 768px) {
    .product-filter ul li {
      width: 31%; } }
  @media (min-width: 1200px) {
    .product-filter ul li {
      width: auto;
      padding: 0 40px 0 0; } }

.sale-ended-label {
  display: none; }

.item-disabled .sale-ended-label {
  display: block;
  color: grey; }

.product-list .product-item-image {
  position: relative; }

.product-list .product-item-image .item-new {
  position: absolute;
  top: -15px;
  left: -15px; }

.item-new {
  display: inline-block;
  color: #fff;
  background: #fe1416;
  padding: 5px 10px;
  font-weight: 700;
  letter-spacing: 1px; }

.item-new.in-detail {
  position: relative;
  top: -20px; }

.manual-box {
  padding: 40px 0; }
  .manual-box-content {
    display: flex;
    flex-wrap: wrap; }
    .manual-box-content.manual-colum-1 .manual-item {
      width: 100%; }
    .manual-box-content.manual-colum-2 .manual-item {
      width: 100%; }
      @media (min-width: 545px) {
        .manual-box-content.manual-colum-2 .manual-item {
          width: 50%; } }
    .manual-box-content.manual-colum-3 .manual-item {
      width: 100%; }
      @media (min-width: 768px) {
        .manual-box-content.manual-colum-3 .manual-item {
          width: 33.33%; } }
    .manual-box-content.manual-colum-4 .manual-item {
      width: 100%; }
      @media (min-width: 545px) {
        .manual-box-content.manual-colum-4 .manual-item {
          width: 50%; } }
      @media (min-width: 992px) {
        .manual-box-content.manual-colum-4 .manual-item {
          width: 25%; } }
    .manual-box-content.manual-colum-more .manual-item {
      width: 100%; }
      @media (min-width: 545px) {
        .manual-box-content.manual-colum-more .manual-item {
          width: 50%; } }
      @media (min-width: 992px) {
        .manual-box-content.manual-colum-more .manual-item {
          width: 25%; } }
      @media (min-width: 1400px) {
        .manual-box-content.manual-colum-more .manual-item {
          width: 20%; } }

.manual-item {
  border-left: 1px solid #0084f6;
  border-right: 1px solid #0084f6;
  border-bottom: 5px solid #005ba9;
  flex-grow: 1;
  background: #e0effb;
  margin: 0 0 20px -1px; }
  .manual-item-header {
    background: #005ba9;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    min-height: 55px; }
  .manual-item-content {
    background: #e0effb;
    padding: 10px 10px; }
  .manual-item-footer {
    background: #005ba9;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .selectric-wrapper:focus {
    background: #eeeff4;
    color: #c7c7c7;
    border-bottom: 2px solid #c7c7c7; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border-radius: 0px;
  background: #eeeff4;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 60px 0 15px;
    font-size: 15px;
    line-height: 60px;
    color: blue;
    height: 60px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #e0e0e0;
    text-align: center;
    font: 0/0 a;
    *font: 20px/60px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 15px;
      content: "\e9b4";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #c7c7c7; }

.selectric-focus .selectric {
  border-color: #e0e0e0; }

.selectric-hover .selectric {
  border-color: #e0e0e0; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #e0e0e0;
    border-bottom: 0px; }
    .selectric-open .selectric .button {
      color: #c7c7c7;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #c7c7c7;
  border: 1px solid #e0e0e0;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: blue;
    background: #e4e4eb;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: blue; }
    .selectric-items li.highlighted {
      color: blue; }
    .selectric-items li:hover {
      background: #eeeff4; }
    .selectric-items li:nth-child(odd) {
      background: #d5d5e0; }
      .selectric-items li:nth-child(odd):hover {
        background: #c0c3d6; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

/* laoder */
/* arrows setting */
/* thumb setting */
/* vertical differencies */
/* calcs */
/* spinner keyframe */
@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* horizontal code */
.pux-gallery-main {
  background-color: #fff;
  padding: 0 27px;
  margin: 0 0 10px 0;
  height: 495px;
  display: table;
  width: 100%; }
  .pux-gallery-main-image {
    margin-bottom: 0;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 441px;
    z-index: 1;
    border: 1px solid #dadada; }
    .pux-gallery-main-image img {
      max-height: 441px; }
  .pux-gallery-main.loading {
    position: relative; }
    .pux-gallery-main.loading:before {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #fff;
      opacity: 0.8; }
    .pux-gallery-main.loading:after {
      content: '';
      z-index: 3;
      border: 10px solid #d8d5dc;
      /* Light grey */
      border-top: 10px solid #956688;
      /* Blue */
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: loader 2s linear infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px; }

.pux-gallery-carousel {
  position: relative;
  height: 90px;
  overflow: hidden;
  width: 100%; }
  .pux-gallery-carousel-slick {
    margin: 0 24px;
    position: initial; }
    .pux-gallery-carousel-slick .slick-slide {
      padding-left: 4px;
      padding-right: 4px;
      display: table;
      margin: 0;
      border: 0; }
      .pux-gallery-carousel-slick .slick-slide-inner {
        height: 90px;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #dadada; }
        .pux-gallery-carousel-slick .slick-slide-inner img {
          display: inline-block;
          vertical-align: middle;
          max-height: 68px; }
    .pux-gallery-carousel-slick .slick-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      -webkit-transform: translate(0%, -50%);
      -moz-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      -o-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
      font-size: 0px;
      width: 20px;
      height: 20px;
      background-color: #406ff9;
      color: #fff;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
      .pux-gallery-carousel-slick .slick-arrow:before {
        font-family: "icomoon";
        font-size: 10px; }
      .pux-gallery-carousel-slick .slick-arrow:focus {
        outline: none;
        box-shadow: none; }
      .pux-gallery-carousel-slick .slick-arrow.slick-disabled {
        border-color: #ddd; }
      .pux-gallery-carousel-slick .slick-arrow:hover {
        background-color: #0f4af7; }
    .pux-gallery-carousel-slick .slick-prev {
      left: 0; }
      .pux-gallery-carousel-slick .slick-prev:before {
        content: ""; }
    .pux-gallery-carousel-slick .slick-next {
      right: 0px; }
      .pux-gallery-carousel-slick .slick-next:before {
        content: ""; }

/* vertical code */
.pux-gallery-container.vertical {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media (min-width: 1200px) {
    .pux-gallery-container.vertical .pux-gallery-main {
      -webkit-flex: 80%;
      -moz-flex: 80%;
      -ms-flex: 80%;
      -webkit-flex: 80%;
      flex: 80%;
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2;
      margin: 24px 0;
      height: 487px;
      max-width: 80%; } }
  @media (min-width: 1200px) {
    .pux-gallery-container.vertical .pux-gallery-carousel {
      height: 543px;
      -webkit-flex: 20%;
      -moz-flex: 20%;
      -ms-flex: 20%;
      -webkit-flex: 20%;
      flex: 20%;
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      -moz-order: 1;
      -ms-order: 1;
      order: 1;
      padding-right: 20px;
      max-width: 20%; }
      .pux-gallery-container.vertical .pux-gallery-carousel-slick {
        height: 503px;
        margin: 20px 0;
        position: relative; }
        .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-slide {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 4px;
          padding-bottom: 4px;
          height: 117.75px; }
          .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-slide-inner {
            height: 117.75px; }
            .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-slide-inner img {
              max-height: 95.75px; }
        .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-arrow {
          top: auto;
          left: 50%;
          -webkit-transform: translate(-50%, 0) rotateZ(90deg);
          -moz-transform: translate(-50%, 0) rotateZ(90deg);
          -ms-transform: translate(-50%, 0) rotateZ(90deg);
          -o-transform: translate(-50%, 0) rotateZ(90deg);
          transform: translate(-50%, 0) rotateZ(90deg); }
        .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-prev {
          top: -20px; }
        .pux-gallery-container.vertical .pux-gallery-carousel-slick .slick-next {
          bottom: -20px; } }
