
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-righ: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-righ: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-righ: $fluid-container-padding-right-xl;
    }
}

.header {
  position: fixed;
  z-index: 100;
  top: -13px;
  left: 0;
  width: 100%;
  background: $base-white;
  white-space: nowrap;
  text-align: right;
  height: 60px;
  border-bottom: 1px solid $color-line-dark;
  font-size: 0;
  @include transition(0.3s);

    &-logo {
        max-width: 40%;
        float: left;
        padding: 22px 0 0;
        line-height: 26px;
        min-height: 26px;
    }

    &-menu {
      display: inline-block;
      vertical-align: top;
      text-align: left;
        ul {
          padding: 0;
          margin: 0;
          color: $main-color;
          li {
            display: inline-block;
            position: relative;
            a {
                display: block;
                color: inherit;
                padding: 24px 20px;
                text-transform: uppercase;
                @include font-size(16px);
                @include transition-custom(padding, 0.3s);
            }
            ul {
              display: none;
              position: absolute;
              left: 0;
              top: 100%;
              z-index: 100;
              background: $main-color;
              color: $base-white;
              padding: 15px 0;

              li {
                display: block;

                a {
                    padding: 10px 30px;
                    text-transform: none;
                    font-weight: 400;
                    @include font-size(15px);
                    &:hover {
                        color: $main-color-soft;
                    }
                }
              }
            }

            &.Highlighted,
            &:hover {
              a {
                background: $main-color;
                color: $base-white;
                text-decoration: none;
              }
            }
            &:hover {
              ul {
                display: block;
              }
            }

            &.arrow > a:after {
                display: inline-block;
                vertical-align: top;
                content: '\e943';
                font-family: 'icommon';
                margin: 8px 0 0 10px;
                @include font-size(6px);
            }
          }
        }
    }

    &-search {
      display: inline-block;
      vertical-align: top;
      @include font-size(15px);

        &-toggle {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            padding: 24px 0 13px;
            width: 50px;
            @include font-size(16px);
            @include transition(0.3s);

            &:before {
              content: '\e942';
              font-family: 'icommon';
              display: inline-block;
              width: 100%;
              text-align: center;
            }

            &.active {
                background: $main-color;
                color: $base-white;
            }
        }
        &-form {
            white-space: normal;
            width: 100%;
            display: none;
            position: absolute;
            z-index: 50;
            right: 0;
            top: 100%;
            background: $main-color;
            color: $base-white;
            padding: 20px;
            text-align: center;

            input[type="text"] {
                display: block;
                border-color: $main-color-soft;
                color: $base-white;
                background: none;
                @include placeholder {
                  color: $main-color-mute;
                }
                &:focus{
                  border-color: $base-white;
                }
            }

            input[type="submit"] {
                float: none;
                background: $base-white;
                color: $main-color;
                margin: 15px 0 0;

                &:hover {
                    color: $main-color-hover;
                    background: $base-white;
                }
            }
        }
    }

    &-lang {
        display:inline-block;
        vertical-align: top;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        z-index: 900;
        padding: 24px 15px;
        @include font-size(16px);
        @include transition(0.3s);

        .header-language-current {

        }
        .header-language-inner {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 900;
            border: transparent 0px solid;
            padding: 24px 15px 5px;
            background: $base-white;
            @include border-radius-all(0, 0, 3px, 3px);
            @include box-shadow(0 0 1px $color-line-dark);
            ul{
                margin: 0;
                padding: 0;
                list-style-type: none;
                li {
                    //padding: 0 0 10px;
                    a {
                      margin:0 0 10px 0;
                        &:hover {
                            @include box-shadow(0 0 1px $main-color);
                        }
                        &.active{
                          display: none;
                        }
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .cs-cz,
        .sk-sk,
        .hu-hu {
            display: inline-block;
            width: 18px;
            height: 12px;
            background: url('../svg/HEADER_cz-language.svg') no-repeat center center;
            overflow: hidden;
            padding: 0 0 0 100%;
        }

        .sk-sk {
            background-image: url('../svg/HEADER_sk-language.svg');
        }
        .hu-hu {
            background: url('../svg/HEADER_hu-language.svg');
        }


        &:hover {
            .header-language-inner {
                display: block;
            }
        }
    }

    .pux-meanmenu-reveal {
        display: block;
        right: 100px;
        top: 13px;
        border: none;
    }
    .mobile-menu {
        top: 60px;
        @include font-size(15px);

        .mean-nav ul li a.mean-expand {
            padding: 16px 16px 17px !important;
        }
    }

    @include media(sm){
        &-search {
            &-form {
                padding-right: 200px;

                input[type="submit"] {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    margin: 0;
                }
            }
        }
    }

    @include media(md){
        &-search {
            position: relative;

            &-form {
                width: 560px;
            }
        }
    }

    @include media(lg){
        top: 0;
        height: auto;

        &-search {
            &-toggle {
                padding-bottom: 24px;
            }
        }

        .pux-meanmenu-reveal {
            display: none;
        }
    }

    @include media(xl){

        &-menu {
            ul {
                li {
                    a {
                        padding-left: 28px;
                        padding-right: 28px;
                    }
                }
            }
        }

        &-lang, .header-language-inner {
            padding-left: 28px;
            padding-right: 28px;
        }
    }
}

.header-placholder {
    height: 45px;
    @include transition(0.3s);

    @include media(lg){
        height: 70px;
    }
}

.scrolling {
    .header {
        top: -13px;

        &-menu > ul > li > a,
        &-search-toggle,
        &-lang {
            padding-bottom: 13px;
        }
    }
    .header-placholder {

    }
    @include media(lg){
        .header-placholder {
            height: 30px;
        }
    }
}

.promo {
  position: relative;

  &-item {
      background-repeat: no-repeat;
      background-position: 15% 50%;
    @include background-size;

    &-content {
      margin: 70px 0 0;
      min-height: 350px;
      padding: 35px 45px;
      color: $base-white;
      font-weight: 400;
      @include font-size(18px);

      h1, h2, h3, h4 {
          color: inherit;
      }

      &-title {
          @include pux-scale-font(36px, 28px, 48px, 36px);
      }

      &-btn {
        display: table;
        background: $main-color;
        color: $base-white !important;
        padding: 10px 40px;
        margin: 30px 0 0;

        &:hover,
        &:focus {
            text-decoration: none;
        }
      }
      &-light {
          color: $base-black;
      }
      &-dark {
        background: rgba($second-color, 0.8);
        a {
            color: $main-color-light;
        }
        .promo-item-content-btn {
            background: $base-white;
            color: $main-color !important;
        }
      }
    }
  }

  &-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    &-container {
      position: relative;
    }

    &-btn {
      position: absolute;
      top: -20px;
      display: block;
      width: 40px;
      height: 40px;
      background: $main-color;
      color: $base-white;
      overflow: hidden;

      &:before {
        content: '\e90b';
        font-family: 'icommon';
        display: block;
        width: 100%;
        line-height: 40px;
        text-align: center;
      }

      &:hover,
      &:focus {
        background: $main-color-soft;
        color: $main-color;
        text-decoration: none;
      }

      &-prev {
        left: 0px;
      }
      &-next {
        right: 0px;

        &:before {
          content: '\e90c';
        }
      }
    }
  }

  @include media(xxl){
      &-nav {
          &-btn {
              &-prev {
                left: -85px;
              }
              &-next {
                right: -85px;
              }
          }
      }
  }
}

.product-box {
  display: block;
  margin: 0 0 30px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  padding: 15px 15px 180px;
  overflow: hidden;
  position: relative;
  color: $base-black;
  @include gradient-vertical;

  span {
    display: table;
    padding: 20px 0 0;
    color: $main-color;
  }

  &-title {
      font-weight: 400;
      line-height: 1.3em;
      margin: 0;
  }

  img {
      position: absolute;
      max-width: 320px;
      top: 50%;
      right: 20px;
      @include transition(0.3s);
  }

  &:hover {
      text-decoration: none;
      color: $base-black;

      span {
          color: $main-color-hover;
      }

      img {
          @include scale(1.1, 1.1);
      }
  }

  &-scient {
    @include gradient-vertical(#bce1de, #addbd7);

    img {

    }
  }
  &-office {
    @include gradient-vertical(#e6ed96, #e1e97f);

    img {

    }
  }
  &-print {
    @include gradient-vertical(#fec66c, #feba4c);

    img {
        right: -50px;
    }
  }

  @include media(xs){
      img {
          top: 30%;
      }
  }

  @include media(sm){
      padding-bottom: 100px;
      padding-right: 250px;

      img {
          top:10%;
      }
  }

  @include media(md){
        height: 200px;
  }
  @include media(lg){

        padding: 35px 300px 35px 45px;

        span {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: inherit;
        }

        &-scient {
            @include gradient-vertical(#bce1de, #addbd7);

            img {
                top: -50px;
            }
          }
          &-office {
            @include gradient-vertical(#e6ed96, #e1e97f);

            img {
                top: 10px;
            }
          }
          &-print {
            @include gradient-vertical(#fec66c, #feba4c);

            img {
                right: -50px;
            }
        }
    }
}

.product-box-alt.product-box {
    @include media(lg){
        padding: 20px 30px;
        height: 240px;
        span {
            position: relative;
            padding: 20px 0 0;
        }
        img {
            width: 50%;
        }
        &-scient img {
            top: 110px;
            right: 20px;
        }
        &-office  img {
            top: 110px;
            right: 20px;
        }
        &-print  img {
            width: 70%;
            top: 110px;
            right: -40px;
        }
    }
}

.news-banner {
    display: block;
    height: 400px;
    position: relative;
    background-position: center center;
    @include background-size;

    &-content {
      display: block;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: $base-white;
      padding: 30px;
      @include font-size(28px);
      @include gradient-vertical(rgba($second-color, 0.8), #000);

      span {
          display: table;
          margin: 20px 0 0;
          color: inherit;
          @include font-size($min-font-size);
      }
    }

    &:hover {
        .news-banner-content {
            span:after {
                margin-left: 13px;
            }
        }
    }

    @include media(md){
        height: 660px;
    }

    @include media(lg){
        &-content {
            padding: 40px 100px 0 40px;
        }
    }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

.article {
    &-list {
        @include pux-scale(padding-top, 50px, 20px);
    }

    &-item {
        display: block;
        margin: 0 0 30px;
        color: $second-color;

        &:hover {
            color: $main-color;
            span {
                color: $main-color-hover;
            }
        }

        &-image {
            display: block;
            width: 100%;
            height: 260px;
            @include background-size;
        }

        &-content {
            width: 100%;
            background: $color-line;
            padding: 20px 25px;

            &-title {
                display: block;
                font-weight: 400;
                margin: 0 0 15px;
                color: $base-black;
                @include pux-scale-font(28px, 22px);
            }

            span {
                display: table;
                margin: 25px 0 0;
                color: $main-color;
                font-weight: 400;
                @include font-size(16px);

                &:before {
                    display: inline-block;
                    vertical-align: baseline;
                    position: relative;
                    top: -1px;
                    content: '\e90c';
                    font-family: 'icommon';
                    margin: 0 10px 0 0;
                    padding: 0 0 2px;
                    @include font-size(12px);
                    @include transition(0.1s);
                }
            }
        }
    }

    @include media(md){

        &-item {
            display: flex;
            &-image {
                width: 400px;
                height: 100%;
            }
            &-content {
                padding: 20px 30px;
            }
        }
    }

}

.map {
    &-search {
        padding: 0 0 30px;
        text-align: right;

        input[type="text"]{
            display: inline-block;
            vertical-align: top;
            width: 270px;
            max-width: 60%;
            margin: 0 7px 0 0;
        }
        input[type="submit"],
        a {
            width: 160px;
            max-width: 35%;
            display: inline-block;
            height: 45px;
            line-height: 45px;
            background: $main-color;
            color: $base-white;
            border: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            text-align: center;
            @include border-radius(3px);

            &:focus,
            &:hover{
                background: $main-color-hover;
                color: $base-white;
            }
        }
    }

    @include media(md){
        &-search {
            margin-top: -70px;
        }
    }
    @include media(xxl){
        &-search {
            margin-top: -85px;
        }
    }

    .marker-detail-position {
        min-width: 300px;
        margin-left: -40px;
    }

    .marker-detail-close {
        opacity: 1 !important;
        img {
            display: none !important;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            width: 2px;
            height: 10px;
            background: $base-white;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -1px;
            @include rotate(45deg);
        }
        &:after {
            @include rotate(-45deg);
        }
    }

    .marker-detail-flyout {
        width: 100% !important;
        > div:nth-child(1){
            // arrow
            display: none;
        }
        > div:nth-child(2){
            background: $main-color !important;
            border: none !important;
            width: 100% !important;
            -webkit-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            border-radius: 5px !important;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin: 0 0 0 -5px;
                @include triangle-bottom(5px, 5px, 10px, $main-color);
            }


        }
        > div:nth-child(3){
            display: none;
        }
        > div:nth-child(4){
            background: none !important;
        }
    }

    .marker-detail-content {
        color: $base-white;
        padding: 20px 20px 0;
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
        @include font-size(15px);

        * {
            color: inherit;
        }

        h2 {
            @include font-size(18px);
            margin: 0 0 20px;
        }

        a {
            color: $main-color-soft;
        }
    }
}

.store {

    &-list {
        margin: 50px 0;
        color: $second-color;
    }
    &-item {
        padding: 20px 25px;
        height: 100%;
        background: $color-line;
        font-weight: 300;

        h2 {
            font-weight: 700;
            margin: 0 0 10px;
            @include font-size(18px);
        }

        strong {
            font-weight: 400;
        }
    }

    @include media(md){
        &-item {
            padding: 25px 30px;
        }
    }
}

.office {
    color: $base-black;
    font-weight: 400;
    @include font-size(18px);

    &-contact {
        line-height: 2em;
        em {
            font-style: normal;
            display: inline-block;
            width: 70px;
        }
    }
}

.not-found {
    img {
        display: block;
        margin: 50px auto;
    }
}

.search {
      &-form {
          text-align: center;
            margin: 0 0 30px;
          input[type="text"] {
              display: inline-block;
              width: 100%;
              border-color: $color-line-dark;
              color: $base-black;
              background: none;
              margin: 0 15px 15px 0;
              vertical-align: top;
              @include placeholder {
                color: $main-color-mute;
              }
              &:focus{
                border-color: $main-color;
              }
          }

          input[type="submit"] {
              margin: 0;
              background: $main-color;
              color: $base-white;

              &:hover {
                  color: $base-white;
                  background: $main-color-hover;
              }
          }
      }

      &-item {
          &-box {
              border: 1px solid $color-line;
              padding: 20px;
              margin: 0 0 20px;
          }
          &-name {
              display: block;
              margin: 0 0 20px;
              font-weight: 700;
              @include pux-scale-font(28px, 20px);
          }

          &-perex {

          }

          &-relevation {
              margin: 0 -20px;
              position: relative;
              top: 20px;
              &-bar {
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  background: $main-color;
                  color: $base-white;
                  @include font-size(12px);
              }
          }
      }

      @include media(sm){
        &-form {
            text-align: left;
            input[type="text"] {
                display: inline-block;
                width: 300px;
                max-width: 50%;
            }
        }
    }

    @include media(md){
        &-item {
          &-box {
              padding: 30px;
              margin: 0 0 30px;
          }

          &-relevation {
              margin: 0 -30px;
              position: relative;
              top: 30px;
          }
      }
    }
}

.show-more-container {
    text-align: center;
    padding: 40px 0 0;
    a.arrow-down-link {
        display: inline-block;
        padding: 15px 40px;
        background: $main-color;
        color: $base-white;

        &:hover {
            background: $main-color-hover;
            color: $base-white;
        }
    }
}

.footer{
  background: $main-color;
  color: $base-white;
  padding: 45px 0 0;
  text-align: center;

  a {
    color: inherit;
  }
  ul {
    list-style-type: none;
    padding: 0;

    li {
        padding: 0 0 20px;
    }
  }

  &-contact {
      font-weight: 700;
      @include font-size(18px);

      span {
          display: inline-block;
          vertical-align: middle;
          border: 2px solid $main-color-light;
          color: $main-color-light;
          width: 32px;
          text-transform: uppercase;
          text-align: center;
          margin: 0 15px 0 0;
          padding: 2px 0 0;
          @include font-size(8px);
          @include border-radius(3px);
      }

      ul {
          li {
            &:before {
                display: inline-block;
                vertical-align: baseline;
                position: relative;
                font-family: 'icommon';
                margin: 0 10px 0 0;
                color: $main-color-light;
                font-weight: 300;
                @include font-size(17px);
            }
            &.footer-contact-phone:before { content: '\e93d' }
            &.footer-contact-fax:before { content: '\e93b' }
            &.footer-contact-email:before { content: '\e93c' }

          }
      }
  }

  &-copyright {
    background: $base-black;
    padding: 20px 0;
    margin: 20px 0 0;

    a {
      color: $main-color-light;
    }

    &-right {
      padding: 15px 0 0;
      display: block;
    }
  }

  @include media(md){
      text-align: left;

      &-copyright {
        &-right {
            padding: 0;
            float: right;
        }
      }
  }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}
