@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.grid {
    > div {

    }
    &-small {
        margin: 0 -5px;
        > div {
            padding: 0 5px;
        }
    }
    @include media(md){
        display: flex;
        &-5 {
            > div {
                float: left;
                width: 20%;
            }
        }
    }
}

.block {
  padding: 30px 0;

  &-soft {
      background: $color-line;
  }

  &-title {
        display: block;
        font-weight: 700;
        text-align: center;
        color: $second-color;
        margin-bottom: 70px;
        @include font-size(28px);
  }

  @include media(md){
    padding: 60px 0;
  }
}

.ico-tooltip {
    position: relative;
    top: -2px;
    margin: 0 0 0 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    background: $main-color;
    text-align: center;
    color: $base-white;
    cursor: pointer;
    @include border-radius(50%);

    &:before {
        content: 'i';
        font-family: inherit !important;
        @include font-size(12px);
    }

    &:hover {

    }
}

.tooltip {

    &.show {
        @include opacity(1);
    }

    &.bs-tooltip-right .arrow:before { border-right-color: $base-white; }
    &.bs-tooltip-left .arrow:before { border-left-color: $base-white; }
    &.bs-tooltip-top .arrow:before { border-top-color: $base-white; }
    &.bs-tooltip-bottom .arrow:before { border-bottom-color: $base-white; }

    .arrow {

        &:before {
            z-index: 2;
        }
        &:after {
            content: "";
            position: absolute;
            left: -1px;
            top: -1px;
            z-index: 1;
            border-color: transparent;
            border-style: solid;
            margin-top: -3px;
            border-width: 6px 6px 6px 0;
            border-right-color: $color-line-dark;
        }
    }
    &-inner {
        background: $base-white;
        color: $base-black;
        padding: 20px;
        @include box-shadow(0px 0px 1px $base-black);
    }
}
.tooltiptext{
    display: none;
}
.tooltipbox {
    background: $base-white;
    padding: 15px;
    border: 1px solid $color-line-dark;
    color: $second-color;
    min-width: 250px;
    z-index: 80 !important;
    @include font-size(15px, 21px);

    &.qtip-pos-rc,
    &.qtip-pos-lc {
        margin: 0 0 0 20px;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: -15px;
            top: 50%;
            z-index: 2;
            border-color: transparent;
            border-style: solid;
            margin-top: -10px;
            border-width: 10px 15px 10px 0;
            border-right-color: $base-white;
        }
        &:after {
            z-index: 1;
            margin-top: -11px;
            left: -16px;
            border-width: 11px 16px 11px 0;
            border-right-color: $color-line-dark;
        }
    }
    &.qtip-pos-rc {
        margin: 0 0 0 -20px;
        &:after,
        &:before {
            @include rotate(180deg);
            left: 100%;
        }
    }


    &.qtip-pos-lb,
    &.qtip-pos-rb {
        margin-top: -10px;
        &:after,
        &:before {
            display: none;
        }
    }
}

.flex-grid {
	display: -webkit-flex;
	display: -moz-flex;
	display: flex;
	-moz-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-moz-flex-direction: wrap;
	-webkit-flex-direction: row;
	flex-wrap: wrap;
}

.flex-item {
	position: relative;
}

.box {
    height: 100%;
    padding: 0 0 30px;
}

#map-canvas {
    @include pux-scale(height, 500px, 300px);
}

.marker-detail,
.map-points {
    display: none;
}

.cookie-law-approval {
    &-wrapper {
        width: 100%;
        text-align: center;
        background: $base-white;
        border-top: $color-line-dark 1px solid;
        color: $main-color;
        padding: 8px 15px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;

    }
    &-content {

        a {
            color: inherit;
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: underline;
            }
        }

    }
    &-close {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        color: $base-white;
        background: $main-color;
        padding: 0 20px !important;
        position: relative;
        margin: 0 0 0 30px;
        text-transform: uppercase;

        @include border-radius(4px);

        &:hover {
            text-decoration: none;
            color: $base-white;
            background: $main-color-hover;
        }
    }

}
