
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 16px;
    height:100%;
}

form,
body{
    @include font-size(15px);
    font-family:$font-family;
    font-weight: 300;
    height:100%;
    color: $second-color;
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
  .pt-#{$value} {
    @if($value > 20){
      @include pux-scale("padding-top", $value, 20);
    } @else {
      padding-top: #{$value}px;
    }
  }

  .pb-#{$value} {
    @if($value > 20){
      @include pux-scale("padding-bottom", $value, 20);
    } @else {
      padding-bottom: #{$value}px;
    }
  }

  .mt-#{$value} {
    @if($value > 20){
      @include pux-scale("margin-top", $value, 20);
    } @else {
      margin-top: #{$value}px;
    }
  }

  .mb-#{$value} {
    @if($value > 20){
      @include pux-scale("margin-bottom", $value, 20);
    } @else {
      margin-bottom: #{$value}px;
    }
  }
}

h1,
.h1{
    font-weight: 700;
    color: $second-color;
    @include pux-scale-margin(0, 0, 40px, 0, 28px);
    @include pux-scale-font(36px, 28px, 48px, 40px);
}

h2,
.h2{
    font-weight: 700;
    color: $second-color;
    @include pux-scale-margin(0, 0, 32px, 0, 22px);
    @include pux-scale-font(28px, 24px, 40px, 32px);
}

h3,
.h3{
    font-weight: 400;
    color: $second-color;
    @include pux-scale-margin(0, 0, 32px, 0, 22px);
    @include pux-scale-font(28px, 22px, 40px, 32px);
}

h4,
.h4{
    font-weight: 700;
    color: $second-color;
    @include pux-scale-font(18px, 16px, 30px, 28px);
    @include pux-scale-margin(0, 0, 20px, 0, 15px);
}

big,
.perex{
    color: $base-black;
    font-weight: 400;
    @include pux-scale-font($font-size-perex, $min-font-size-perex, $line-height-perex);
}

small,
.text-small{
    @include pux-scale-font($font-size-small, $min-font-size-small, $line-height-small);
}

a{
    color: $color-link;
    cursor: pointer;
    &:focus,
    &:hover{
        outline:none;
        color: $main-color-soft;
        text-decoration: none;
    }
}

.btn{
  display: inline-block;
  @include border-radius(0);
  border: none;
  padding:13px 40px;
  @include box-shadow(none);
  cursor: pointer;
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
}

.btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-primary-background, 5);
      color: $btn-primary-color;
  }
}

.btn-default{
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-default-background, 5);
      color: $btn-default-color;
  }
} 

.btn-white {
  background: $base-white;
  color: $main-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $main-color-soft;
      color: $main-color;
  }
}

.btn-arrow {
    &:after {
        content: '\e90c';
        font-family: 'icommon';
        margin: 0 0 0 10px;       
        @include font-size(10px);
        @include transition(0.1s);
    }
    &:hover {        
        text-decoration: none;
        &:after {
            margin-left: 13px;
        }
    }
}

.btn-back {
    display: block;
    text-align: right;
    margin: 0 0 30px;
    @include font-size(16px);
    
    &:before {
        content: '\e90c';
        font-family: 'icommon';
        display: inline-block;
        position: relative;
        top: -1px;
        margin: 0 10px 0 0;    
        @include rotate(180deg); 
        @include font-size(10px);
        @include transition(0.1s);
    }
    &:hover {        
        text-decoration: none;
        &:before {
            margin-right: 13px;
        }
    }
    
    @include media(sm){
        float: right;        
    }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:400px;
    ol{
        color: $base-black;
        margin: 0 0 25px;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 10px 0;
            padding:0 0 0 20px;
            &:before{
                font-family: inherit !important;
                content: counter(my-ol);
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:4px;
                font-weight:400;
            }
        }
    }
    ul{
        color: $base-black;
        margin: 0 0 25px;
        padding:0;
        list-style:none;
        li{            
            margin:0 0 10px 0;
            padding:0 0 0 15px;
            position:relative;
            &:before{
                content:"";
                position:absolute;
                top:9px;
                left:0;
                @include triangle-right(3px, 3px, 4px, $ul-bullet);
            }
        }
    }
    table{
      color: $base-black;
      width:100%;
      tr{
        border-bottom: 1px solid $table-tr-border;
        td{
          padding:10px 15px;
        }
        &:nth-child(even){
          background: $table-even-background;
        }
      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media(md){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:10px 15px;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 20px 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media(md){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media(md){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 15px 0 0;
                  @include media(md){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}
