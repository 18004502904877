@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.product {
    ul, ol {
        color: $second-color;
    }
    &-title {

    }
    &-image {

    }
    &-frame {
        background: $color-line;
        padding: 15px;
        margin: 30px 0;

        strong {
            font-weight: 700;
            color: $second-color;
            float: left;
            padding: 10px 0 0;
            @include font-size(18px);
        }

        &-color {
            margin-top: -10px;
            ul {
                clear: both;
                font-size: 0;
                margin: 0 -20px 0 0;
                padding: 0;

                li {
                    display: inline-block;
                    padding: 0;
                    width: 20%;
                    margin: 15px 0 0;
                    &:before {
                        display: none;
                    }
                    a {
                        display: block;
                        width: 30px;
                        height: 30px;
                        border: 2px solid $base-white;
                        overflow: hidden;
                        text-indent: 40px;
                        @include border-radius(50%);

                        &:hover {
                            border: 2px solid $second-color;
                        }
                    }

                    &.active a {
                        border: 3px solid $second-color;
                    }
                }
            }
        }

        &-price {
            text-align: right;
            font-weight: 700;
            color: $main-color;
            @include font-size(28px);
        }

        hr {
            margin: 20px -15px;
            border-top: 2px solid $base-white;
        }

    }

    &-download {
        ul {
            li {
                padding: 0;
                &:before {
                    display: none;
                }
                a {
                    display: block;

                    &:before {
                        content: '\e93a';
                        display: inline-block;
                        vertical-align: middle;
                        font-family: 'icomoon';
                        margin: 0 20px 0 0;
                        @include font-size(36px);
                    }

                    &:hover {

                    }
                }
            }
        }
    }

    @include media(sm){
        &-frame {
            padding: 30px;

            &-color {
                ul {
                    margin: 0 -30px 0 0;
                }
            }
            hr {
                margin: 25px -30px;
            }
        }
    }

    @include media(xl){
        &-frame {
            margin: 40px 0 50px;
            &-color {
                ul {
                    li {
                        width: 10%;
                    }
                }
            }

        }
        &-download {
            ul {
                li {
                    float: left;
                    width: 50%;
                }
            }
        }
    }
}

.product-list {

    .items-container {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
    }
    .show-more-container {
        text-align: center;
        padding: 40px 0 20px;

        .arrow-down-link {
            display: inline-block;
            padding:13px 40px;
            background: $btn-primary-background;
            color: $btn-primary-color;
            &:focus,
            &:hover{
                text-decoration: none;
                background: darken($btn-primary-background, 5);
                color: $btn-primary-color;
            }
        }
    }

    .product-item {
        display: block;
        padding: 25px;
        background: $base-white;
        display: block;
        border: 2px solid transparent;
        height: 100%;

        &-image {
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            height: 220px;
            margin: 0 0 15px;
            @include background-size(contain);
        }
        &-title {
            display: block;
            font-weight: 700;
            margin: 0 0 15px;
            color: $main-color;
            @include font-size(22px);
        }
        &-label {
            color: $base-black;

            > *:last-child {
                margin-bottom: 0 !important;
            }
        }

        &:hover,
        &:focus {
            border-color: $main-color;
            color: inherit;
        }
    }
}

.product-tab {

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    &-item {
        display: block;
        background: $main-color;
        padding: 15px;
        color: $base-white;
        height: 100%;
        margin: 0 0 10px;

        &:after {
            content: '';
            display: table;
            clear: both;
        }

        &-image {
            display: none;
            vertical-align: middle;
            width: 80px;
            margin: 0 10px 0 0;
            background-position: center center;
            background-repeat: no-repeat;
            @include background-size(contain);

            &:before {
                content: '';
                display: block;
                padding: 100% 0 0;
            }
        }
        &-label {
            font-weight: 400;
        }

        &:hover,
        &:focus {
            background: $second-color;
            text-decoration: none;
            color: $base-white;
        }

        &.active {
            background: $color-line-dark;
            color: $main-color;
        }
    }

    @include media(sm){
        &-item {
            &-image {
                display: inline-block;
            }
        }
    }
    @include media(md){
        &-item {

            text-align: center;
            &-image {
                display: block;
                width: auto;
                margin: 0 10% 20px;
            }
        }
    }
}

.product-filter {
    padding: 40px 0 40px;
    ul {
        li {
            display: block;
            &:before {
                display: none;
            }
            input[type="checkbox"]{
                display:none;
                & +label{
                    position:relative;
                    padding:0 0 10px 25px;
                    margin:0;
                    display: inline-block;
                    cursor: pointer;
                    &:before{
                        content: '\e947';
                        display: inline-block;
                        vertical-align: middle;
                        font-family: 'icomoon';
                        margin: 0 8px 0 0;
                        color: $main-color;
                        @include font-size(18px);
                    }
                }
                &:checked{
                    & +label{
                        &:before{
                            content: '\e948';
                        }
                    }
                }
            }

            a {
                color: $second-color;
                &:before {
                    content: '\e947';
                    display: inline-block;
                    vertical-align: middle;
                    font-family: 'icomoon';
                    margin: 0 8px 0 0;
                    color: $main-color;
                    @include font-size(18px);
                }
                &:hover:before {
                    color: $second-color;
                }
            }
            .ico-tooltip {
                float: right;
            }
            &.active a:before {
                content: '\e948';
            }
        }
    }

    @include media(xs){
        ul {
            li {
                display: inline-block;
                width: 48%;

                .ico-tooltip {
                    float: none;
                }
            }
        }
    }

    @include media(md){
        ul {
            li {
                width: 31%;
            }
        }
    }
    @include media(xl){
        ul {
            li {
                width: auto;
                padding: 0 40px 0 0;
            }
        }
    }
}

.sale-ended-label {
  display: none; }

.item-disabled .sale-ended-label {
  display: block;
  color: grey; }

.product-list .product-item-image {
  position: relative; }
  .product-list .product-item-image .item-new {
    position: absolute;
    top: -15px;
    left: -15px; }

.item-new {
  display: inline-block;
  color: #fff;
  background: #fe1416;
  padding: 5px 10px;
  font-weight: 700;
  letter-spacing: 1px; }
  .item-new.in-detail {
    position: relative;
    top: -20px; }


.manual-box{
  padding:40px 0;
  &-content{
    display: flex;
    flex-wrap: wrap;
    &.manual-colum-1{
      .manual-item{
        width: 100%;
      }
    }
    &.manual-colum-2{
      .manual-item{
        width: 100%;
        @include media(sm){
          width: 50%;
        }
      }
    }
    &.manual-colum-3{
      .manual-item{
        width: 100%;
        @include media(md){
          width: 33.33%;
        }
      }
    }
    &.manual-colum-4{
      .manual-item{
        width: 100%;
        @include media(sm){
          width: 50%;
        }
        @include media(lg){
          width: 25%;
        }
      }
    }
    &.manual-colum-more{
      .manual-item{
        width: 100%;
        @include media(sm){
          width: 50%;
        }
        @include media(lg){
          width: 25%;
        }
        @include media(xxl){
          width: 20%;
        }
      }
    }
  }
}

.manual-item{
  border-left:1px solid lighten($main-color, 15);
  border-right:1px solid lighten($main-color, 15);
  border-bottom:5px solid $main-color;
  flex-grow: 1;
  background: #e0effb;
  margin:0 0 20px -1px;
  &-header{
    background: $main-color;
    color:#fff;
    text-align: center;
    font-weight: bold;
    padding:5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    min-height: 55px;
  }
  &-content{
    background: #e0effb;
    padding:10px 10px;
  }
  &-footer{
    background: $main-color;
    color:#fff;
    text-align: center;
    padding:5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
}
