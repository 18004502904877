@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nkirei');
  src:  url('fonts/icomoon.eot?nkirei#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?nkirei') format('woff2'),
    url('fonts/icomoon.ttf?nkirei') format('truetype'),
    url('fonts/icomoon.woff?nkirei') format('woff'),
    url('fonts/icomoon.svg?nkirei#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-MAP-PIN_all-in-one .path1:before {
  content: "\e900";
  color: rgb(0, 91, 169);
}
.icon-MAP-PIN_all-in-one .path2:before {
  content: "\e901";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path3:before {
  content: "\e902";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path4:before {
  content: "\e903";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path5:before {
  content: "\e904";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path6:before {
  content: "\e905";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path7:before {
  content: "\e906";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path8:before {
  content: "\e907";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path9:before {
  content: "\e908";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_all-in-one .path10:before {
  content: "\e909";
  margin-left: -0.771484375em;
  color: rgb(255, 255, 255);
}
.icon-MAP-PIN_multiple:before {
  content: "\e90a";
  color: #005ba9;
}
.icon-SLIDER_left-arrow:before {
  content: "\e90b";
  color: #fff;
}
.icon-SLIDER_right-arrow:before {
  content: "\e90c";
  color: #fff;
}
.icon-motiv .path1:before {
  content: "\e90d";
  color: rgb(0, 91, 169);
}
.icon-motiv .path2:before {
  content: "\e90e";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path3:before {
  content: "\e90f";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path4:before {
  content: "\e910";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path5:before {
  content: "\e911";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path6:before {
  content: "\e912";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path7:before {
  content: "\e913";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path8:before {
  content: "\e914";
  margin-left: -0.955078125em;
  color: rgb(0, 91, 169);
}
.icon-motiv .path9:before {
  content: "\e915";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path10:before {
  content: "\e916";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path11:before {
  content: "\e917";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path12:before {
  content: "\e918";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path13:before {
  content: "\e919";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path14:before {
  content: "\e91a";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path15:before {
  content: "\e91b";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path16:before {
  content: "\e91c";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path17:before {
  content: "\e91d";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path18:before {
  content: "\e91e";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path19:before {
  content: "\e91f";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path20:before {
  content: "\e920";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path21:before {
  content: "\e921";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path22:before {
  content: "\e922";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path23:before {
  content: "\e923";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path24:before {
  content: "\e924";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path25:before {
  content: "\e925";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path26:before {
  content: "\e926";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path27:before {
  content: "\e927";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path28:before {
  content: "\e928";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path29:before {
  content: "\e929";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path30:before {
  content: "\e92a";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path31:before {
  content: "\e92b";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path32:before {
  content: "\e92c";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path33:before {
  content: "\e92d";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path34:before {
  content: "\e92e";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path35:before {
  content: "\e92f";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path36:before {
  content: "\e930";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path37:before {
  content: "\e931";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path38:before {
  content: "\e932";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path39:before {
  content: "\e933";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path40:before {
  content: "\e934";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path41:before {
  content: "\e935";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path42:before {
  content: "\e936";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path43:before {
  content: "\e937";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-motiv .path44:before {
  content: "\e938";
  margin-left: -0.955078125em;
  color: rgb(193, 193, 193);
}
.icon-BULLET_normal-text-bullet:before {
  content: "\e939";
  color: #4b4b4b;
}
.icon-DOWNLOAD_file-download-ico:before {
  content: "\e93a";
  color: #005ba9;
}
.icon-FOOTER_fac-ico:before {
  content: "\e93b";
  color: #84cbe9;
}
.icon-FOOTER_mail-ico:before {
  content: "\e93c";
  color: #84cbe9;
}
.icon-FOOTER_phone-ico:before {
  content: "\e93d";
  color: #84cbe9;
}
.icon-HEADER_cz-language .path1:before {
  content: "\e93e";
  color: rgb(215, 20, 26);
}
.icon-HEADER_cz-language .path2:before {
  content: "\e93f";
  margin-left: -1.5em;
  color: rgb(240, 240, 240);
}
.icon-HEADER_cz-language .path3:before {
  content: "\e940";
  margin-left: -1.5em;
  color: rgb(17, 69, 126);
}
.icon-HEADER_logo-casio:before {
  content: "\e941";
  color: #005ba9;
}
.icon-HEADER_search:before {
  content: "\e942";
  color: #005ba9;
}
.icon-HEADER-MENU_submenu-symbol:before {
  content: "\e943";
  color: #005ba9;
}
.icon-HYPERLINK_bigger-text-arrow_blue:before {
  content: "\e944";
  color: #005ba9;
}
.icon-HYPERLINK_normal-text-arrow_blue:before {
  content: "\e945";
  color: #005ba9;
}
.icon-HYPERLINK_normal-text-arrow_white:before {
  content: "\e946";
  color: #fff;
}
.icon-CHECKBOX_empty:before {
  content: "\e947";
  color: #005ba9;
}
.icon-CHECKBOX_checked:before {
  content: "\e948";
  color: #005ba9;
}
.icon-MAP-PIN_1of2_pin-bg:before {
  content: "\e949";
  color: #005ba9;
}
.icon-MAP-PIN_2of2_ico-in-pin:before {
  content: "\e94a";
  color: #fff;
}
